import { useContext, useEffect, useState } from "react"
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { fetchTeams } from "../functions";
import { HyRContext } from "../context/HyLContext";
import { redApi } from "../../config/Axios";
import { Swals } from "../../utils/swals";
import { matchupLeagues } from "../../utils/matchUpLeagues";
import { useHyRStore } from "./useGamesRender";
import ColumnGroup from "antd/es/table/ColumnGroup";


export const useHyL = () => {

  const {
    ligaName,
    table,
    reloadData,
    setReloadData,
  } = useContext(HyRContext);

  //* STATES
  const [linesChanged, setLinesChanged] = useState([]);
  const [authLineProp, setAuthLineProp] = useState("");
  const [linesChangedHashMap, setLinesChangedHashMap] = useState(new Map());
  const [forceRender, setForceRender] = useState(false)

  const [allLeagues, setAllLeagues] = useState([]);
  const [authLine, setAuthLine] = useState({});
  const [authLineProp2, setAuthLineProp2] = useState("");
  const [automatic, setAutomatic] = useState(false);
  const [date, setDate] = useState(moment().format("Y-MM-DD"));
  const [estatus, setEstatus] = useState('not inited');
  const [fetchLoading, setFetchLoading] = useState(true);
  const [gameSelected, setGameSelected] = useState(null);
  const [indexGame, setIndexGame] = useState(null);
  const [gamesRender, setGamesRender] = useState([]);
  const [gamesTableLoading, setGamesTableLoading] = useState(true);
  // update scores of game
  const [gameToUpdate, setGameToUpdate] = useState({});
  const [historialRender, setHistorialRender] = useState([]);
  const [isPonche, setPoncheStatus] = useState(false);
  const [league, setLeague] = useState(null);
  // ACA BOLUDO
  const [leagueConfig, setLeagueConfig] = useState(null);
  const [leagueSelected, setLeagueSelected] = useState(league)
  const [lineIdSelected, setLineIdSelected] = useState("");
  const [linesByPeriods, setLinesByPeriods] = useState({
    leagues: "",
    typeLines: [],
    periods: ["full"],
  });
  const [lineToEdit, setLineToEdit] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [modalUpdateGame, setModalUpdateGame] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [propiedad, setPropiedad] = useState("");
  const [scoresToUpdate, setScoresToUpdate] = useState({});
  const [scoreStructure, setScoreStructure] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [tableData, setTableData] = useState({ head: [] })
  const [teamsDB, setTeamsDB] = useState([]);
  const [typeLineSelected, setTypeLineSelected] = useState(null);
  const [userCodesTeams, setUserCodesTeams] = useState("legacy");
  const [visible, setVisible] = useState(false);
  const [visibleSuperLiniero, setVisibleSuperLiniero] = useState(false);
  const [dataTimeBot, setDataTimeBot] = useState([]);
  const [dataBot, setDataBot] = useState({name:"",timeAmmount:"",timeStep:"", code:''});
  const [typeLineHistorial, setTypeLineHistorial] = useState('all')
  const [searchUser, setSearchUser] = useState('')
  const [sectedNameLeague, setSectedNameLeague] = useState(ligaName);

  //* METHODS 
  const getTeams = async () => {
    if (league) {
      const { resTeams, structure, leagueConfigData } = await fetchTeams(league);
      setTeamsDB(resTeams);
      setScoreStructure(structure);
      setLeagueConfig(leagueConfigData)
      setAutomatic(league.automatic)
    }
  };
  useEffect(() => {
    if (teamsDB) {
      getTeamsDB_(teamsDB);
    }
  }, [teamsDB])
  const getLeague = async () => {
    try {
      let response = await redApi.get(`/leagues/getleagueid?name=${ligaName}`);
      setLeague(response.data);
      setSectedNameLeague(response.data.name)
      await redApi.get(`/leagues`).then((res) => {
        setAllLeagues(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeLeague = (e) => {
    // window.location.href = `/admin/horarioslineas/${e.target.value}?table=lineas`;
    setSectedNameLeague(e.target.value);
  }
  
  const handleChangeLeagueLines = async (e) => {
    setSelectedValue(e.target.value);


    dataTimeBot.map((item)=> {
      if(e.target.value === item.name && e.target.value !== "donbestapi/Consensus" && e.target.value !== "donbestapi/PLMGM") {
        setDataBot({
          name: item.name,
          timeAmmount: item.timeAmmount,
          timeStep: item.timeStep
        })
      }else if(e.target.value === "donbestapi/Consensus"){
        setDataBot({
          name: e.target.value.split("/")[1],
          timeAmmount: 1,
          timeStep: item.timeStep,
          code: 347
        })
      } else if(e.target.value === "donbestapi/PLMGM"){
        setDataBot({
          name: e.target.value.split("/")[1],
          timeAmmount: 1,
          timeStep: item.timeStep,
          code: 574
        })
      }
    })


    const alertLoadiing = Swal.fire({
      icon: "success",
      title: "Cambiando líneas",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    try {
      const botName = e.target.value.split('/')[0]
      const bot = league.bots.find((bot) => bot.name == botName)
      let fetchURL;
      if (bot.sportBooks.length !== 0) {
        const sportBookName = e.target.value.split('/')[1];
        const sportBook = bot.sportBooks.find((sportBooks) => sportBooks.name == sportBookName)
        fetchURL = `/games/linesAPI?bot=${bot.bot}&league=${league._id}&code=${sportBook.code}&status=${estatus}&date=${date}`;
      } else {
        fetchURL = `/games/linesAPI?bot=${bot.bot}&league=${league._id}&status=${estatus}&date=${date}`;
      }
      setGamesTableLoading(true);
      const { data: newGamesToRender } = await redApi.get(fetchURL);
      setGamesRender(newGamesToRender);
      const resLinesConfig = await redApi.get(`/linesConfig/config?league=${league._id}`);
      // setLeagueConfig(resLinesConfig.data[0])

    } catch (e) {
      await Swals.error("Error", "Las lineas no se han podido sincronizar");
    } finally {
      alertLoadiing.close()
      setGamesTableLoading(false);
    }
  }

  const handleTypeLineHistorial = (e) => {
    setTypeLineHistorial(e.target.value);
  }

  const handleSearchUser = (e) => {
    setSearchUser(e.target.value);
  }

  const loadGames = async (viewLoad) => {
    setGamesTableLoading(viewLoad === false ? false : true);
    try {
      setFetchLoading(viewLoad === false ? false : true);
      const botName = selectedValue.split('/')[0]
      const bot = league?.bots.find((bot) => bot.name == botName)
      let sportBook = { code: '' }
    
      if (bot?.sportBooks.length !== 0) {
        const sportBookName = selectedValue.split('/')[1];
        sportBook = bot?.sportBooks.find((sportBooks) => sportBooks.name == sportBookName)
      }

      if ((table === "lineas" && league) || (table === "juegos" && league)) {
        if (league?.name == 'NBA-S' || league?.name == 'WNBA-S' || league?.name == 'NFL-S' || league?.name == 'NHL-S') {
          const response = await redApi.get(`/games?matchUps=true&date=${date}&league=${league._id}`);
          setGamesRender(response.data);

        } else {
          const response = await redApi.get(
            `/games?date=${date}&league=${league._id}&table=${table}&estatus=${estatus}&futures=true&bot=${botName}&sportbook=${sportBook?.code}`
          );
          setGamesRender(response.data);
         
        }
      } else if (table === "historial" && league) {
        const alertLoading = Swal.fire({
          icon: "success",
          title: "Cargando líneas",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => {
            Swal.showLoading()
          }
        })
        const response = await redApi.get(
          `/lineashistory?date=${date}&league=${league._id}`
        );
        setHistorialRender(response.data);
        alertLoading.close()
        setFetchLoading(false);
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
    setFetchLoading(false);
    setGamesTableLoading(false);
  };
  const getTeamsLeague = async () => {
    try {
      getTeamsDB_(teamsDB);
    } catch (error) {
      console.log(error);
    }
  }
  // const { gamesRender_, gamesTableLoading_, changeLeague2 } = usegamesRender();
  const { gamesRender_, getGamesRender_, teamsDB_, getTeamsDB_, changeStatusGames_, statusGames_ } = useHyRStore();
  useEffect(() => {
    // console.log("statusGames_", statusGames_, "estatus", estatus, statusGames_ !== estatus)
    if (statusGames_ !== estatus) {
      // setEstatus(statusGames_)
    }
  }, [statusGames_])
  const changeLeague = async () => {
    getGamesRender_(league, date, table, estatus);
    getTeams()
  }

  useEffect(() => {
    // if (!league) return;
    // console.log("changeLeague576756", league)
    // if (gamesTableLoading_) {
    //   setGamesTableLoading(gamesTableLoading_)
    // }
    if (gamesRender_) {
      setGamesRender(gamesRender_)
      // console.log("gamesRender_3", gamesRender_)
    }
  }, [gamesRender_])
  useEffect(() => {
    // console.log("useHyL gamesRender", gamesRender)
  }, [gamesRender])
  useEffect(() => {
    changeLeague();

  }, [league]);

  useEffect(() => {
    loadGames();
  }, [selectedValue]);
  const handleChange = (e, index, property, property2) => {
    let newLine = [...gamesRender];
    // console.log({ line: newLine[index][pr                        operty][property2], value: e.target.value, index, property, property2 });
    newLine[index][property][property2].value = e.target.value;
    setGamesRender(newLine);
    setLineToEdit({
      id: gamesRender[index][property]._id,
      [property2]: {
        value: e.target.value,
      },
    });
    setPropiedad(property2);
  };
  const putData = async () => {

    if (league?.name === 'NBA-S' || league?.name === 'WNBA-S' || league?.name == 'NFL-S' || league?.name == 'NHL-S') {
      return await redApi.put(`lines/${lineToEdit.id}?isMatchUp=true`, {
        [propiedad]: lineToEdit[propiedad],
      });
    }
    return await redApi.put(`lines/${lineToEdit.id || "na"}`, {
      [propiedad]: lineToEdit[propiedad],
    });
  };
  const handleAuthLine = async (id, property, value, property2) => {
    setAuthLine({
      id: id,
      [property]: {
        status: "approved",
        value: value,
      },
    });
    setAuthLineProp(property);
    setAuthLineProp2(property2);
  };
  const putAuthLine = async () => {
    if (authLine.id) {
      if (league?.name === 'NBA-S' || league?.name === 'WNBA-S' || league?.name == 'NFL-S' || league?.name == 'NHL-S') {
        let data = {
          [authLineProp]: authLine[authLineProp],
          method: "authorize",
        };
        await redApi
          .put(`lines/${authLine.id || "na"}?isMatchUp=true`, data)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Linea autorizada",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              loadGames(false);
              loadGames(false);
            });
          })
          .catch((e) => console.log(e));
        return;
      }
      let data = {
        [authLineProp]: authLine[authLineProp],
        method: "authorize",
      };
      await redApi
        .put(`lines/${authLine.id || "na"}`, data)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Linea autorizada",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            loadGames(false);
            loadGames(false);
          });
        })
        .catch((e) => console.log(e));
    }
  };
  const putUnauthLine = async () => {
    if (league?.name === 'NBA-S' || league?.name === 'WNBA-S' || league?.name == 'NFL-S' || league?.name == 'NHL-S') {
      let data = {
        [authLineProp]: authLine[authLineProp],
        method: "unauthorize",
      };
      await redApi
        .put(`lines/${authLine.id || "na"}?isMatchUp=true`, {
          [authLineProp]: {
            status: "pending",
            value: authLine[authLineProp].value,
          },
          method: "unauthorize",
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Linea desautorizada",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            loadGames(false);
            loadGames(false);
          });
        })
        .catch((e) => console.log(e));
      return;
    }
    let response = await redApi.put(`lines/${authLine.id || "na"}`, {
      [authLineProp]: {
        status: "pending",
        value: authLine[authLineProp]?.value,
      },
      method: "unauthorize",
    });
    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Linea desautorizada",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        loadGames(false);
        loadGames(false);
      });
    }
  };
  const handleStatusGame = async (id, status) => {
    Swal.fire({
      title: "¿Desea cambiar el estado del juego?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Si`,
      denyButtonText: `No`,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          let response = await redApi.put(`games/${id}`, {
            status: status,
          });
          if (response.status === 200) {
            await Swal.fire({
              icon: "success",
              title: "Partido autorizado",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else if (result.isDenied) {
          await Swal.fire("Los cambios no fueron guardados", "", "info");
        }
      })
      .finally(() => loadGames());
  };
  const handleUpdateGame = async (game) => {
    await Swal.fire({
      title: "¿Desea cambiar el estado del juego a jugando?",
      showCancelButton: true,
      confirmButtonText: `Si`,
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          let response = await redApi.put(`games/${game._id}`, {
            status: "playing",
          });
          if (response.status === 200) {
            await Swal.fire({
              icon: "success",
              title: "El partido esta en juego",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else if (result.isDenied) {
          await Swal.fire("Los cambios no fueron guardados", "", "info");
        }
      })
      .finally(() => loadGames());


  };
  const handleCancelModal = () => {
    setModalUpdateGame(false);
    setGameToUpdate({});
  };
  const handleChangeScores = (e, gameID) => {
    setScoresToUpdate({
      ...scoresToUpdate,
      [e.target.name]: {
        ...scoresToUpdate[e.target.name],
        [e.target.id]: e.target.value,
      },
      gameID: gameID,
    });
    // console.log(scoresToUpdate);
  };
  const handleShowModalSetScores = async (game) => {
    setGameToUpdate(game);
    // console.log({scoreStructure});
    setScoresToUpdate({ scoresLocal: game.localLine.scores, scoresVisit: game.visitingLine.scores });
    setModalUpdateGame(true);
    // console.log({game})
  }
  const handleReCalcScores = async () => {
    try {
      await redApi.post(`/games/reload_scores?date=${date}`).then((r) => {
      });
      await Swals.success(
        "Completado",
        "Se han actualizado los tickets"
      );
    } catch {
      await Swals.error(
        "Error",
        "Ha ocurrido un error al limpiar los cambios"
      );
    } finally {
      setLoading(false);
    }
  }
  const handleAutomatic = async () => {
    try {
      await redApi
        .put(`/leagues/automatic?league=${league._id}`)
        .then((r) => {
          setAutomatic(r.data.league.automatic)
          return r.data
        });
      await Swals.success(
        "Completado",
        "Se ha automatizado la liga"
      );
      await loadGames();
    } catch {
      await Swals.error(
        "Error",
        "Ha ocurrido un error al autorizar los juegos"
      );
    } finally {
      setLoading(false);
    }
  }

  const autorizeLineChange = async () => {
    // setSelectedValue(e.target.value);
    const alertLoadiing = Swal.fire({
      icon: "success",
      title: "Cambiando líneas",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    try {
      const botName = selectedValue.split('/')[0]
      const bot = league.bots.find((bot) => bot.name == botName)
      let fetchURL;
      if (bot.sportBooks.length !== 0) {
        const sportBookName = selectedValue.split('/')[1];
        const sportBook = bot.sportBooks.find((sportBooks) => sportBooks.name == sportBookName)
        fetchURL = `/games/linesAPI?bot=${bot.bot}&league=${league._id}&code=${sportBook.code}&status=${estatus}&date=${date}&autorize=${true}`;
      } else {
        fetchURL = `/games/linesAPI?bot=${bot.bot}&league=${league._id}&status=${estatus}&date=${date}&autorize=${true}`;
      }
      setGamesTableLoading(true);
      const { data: newGamesToRender } = await redApi.get(fetchURL);
      setGamesRender(newGamesToRender);
      const resLinesConfig = await redApi.get(`/linesConfig/config?league=${league._id}`);
      setLeagueConfig(resLinesConfig.data[0])

    } catch (e) {
      console.log("ERROR", e)
      await Swals.error("Error", "Las lineas no se han podido sincronizar");
    } finally {
      alertLoadiing.close()
      setGamesTableLoading(false);
    }
  }
  const handleUpdateScores = async (game) => {
    let missing = false;
    const scoresLocal = document.getElementsByName('scoresLocal');
    const scoresVisit = document.getElementsByName('scoresVisit');
    let scoresObj = { local: {}, visiting: {} };
    for (const score of scoresLocal) {
      if (score.value === '') {
        missing = true;
        break;
      }
      scoresObj.local[score.getAttribute('data-id')] = Number(score.value);
    };
    for (const score of scoresVisit) {
      if (score.value === '') {
        missing = true
        break;
      }
      scoresObj.visiting[score.getAttribute('data-id')] = Number(score.value);

    };
    Swal.fire({
      icon: "success",
      title: "Scores Completos",
      showConfirmButton: false,
      timer: 2000
    })
    Swal.fire({
      title: "¿Desea actualizar los marcadores?",
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonColor: "#832AC0",
      confirmButtonColor: "#BF2A61",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await redApi.put(`/games/${gameToUpdate._id}/set_scores`, { scores: scoresObj, scoreStructure }
        ).then(() => {
          Swal.fire({
            icon: "success",
            title: "Marcadores actualizados",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            setGameToUpdate({});
            window.location.reload();
          });
        });
      }
    });
  };
  const syncScores = async () => {
    setLoading(true);
    setLoadingMessage("Sincronizando scores");
    try {
      await redApi.get(`/games/set_scores?league=${league.name}`).then((r) => r.data);
      await Swals.success("Sincronizado", "Los scores se han sincronizado");
      await loadGames();
    } catch (e) {
      if (e.response.status === 404) {
        await Swals.error("Error", "Esta liga no se encuentra en Yahoo");
      } else {
        await Swals.error("Error", "Los scores no se han podido sincronizar");
      }
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };
  const syncLines = async () => {
    setLoading(true);
    try {
      setLoadingMessage("Sincronizando horarios y lineas");
      await redApi.post("/games/load_lines").then((r) => r.data);
      await Swals.success("Sincronizado", "Las lineas se han sincronizado");
      await loadGames();
      setReloadData(!reloadData);
    } catch (e) {
      await Swals.error("Error", "Las lineas no se han podido sincronizar");
    } finally {
      setLoadingMessage("");
      setLoading(false);  
    }
  };
  const approveGames = async () => {
    setLoading(true);
    try {
      const botName = selectedValue.split('/')[0]
      const bot = league.bots.find((bot) => bot.name == botName)
      let sportBook = { code: '' }
      let fetchURL;
      if (bot.sportBooks.length !== 0) {
        const sportBookName = selectedValue.split('/')[1];
        sportBook = bot.sportBooks.find((sportBooks) => sportBooks.name == sportBookName)
      }
      await redApi
        .post(matchupLeagues.includes(league.name) ? `/games/approve_games?date=${date}&league=${league._id}&matchup=true` : `/games/approve_games?date=${date}&bot=${bot.name}&sportbook=${sportBook.code}`)
        .then((r) => r.data);
      await Swals.success(
        "Completado",
        "Los juegos actuales se han autorizado"
      );
      await loadGames();
    } catch {
      await Swals.error(
        "Error",
        "Ha ocurrido un error al autorizar los juegos"
      );
    } finally {
      setLoading(false);
    }
  };
  const approveGamesLeague = async () => {
    setLoading(true);
    try {
      await redApi
        .post(matchupLeagues.includes(league.name) ? `/games/approve_games?date=${date}&league=${league._id}&matchup=true` : `/games/approve_games?date=${date}&league=${league._id}&bot=${selectedValue}`)
        .then((r) => r.data);
      await Swals.success(
        "Completado",
        "Los juegos actuales se han autorizado"
      );
      await loadGames();
    } catch {
      await Swals.error(
        "Error",
        "Ha ocurrido un error al autorizar los juegos"
      );
    } finally {
      setLoading(false);
    }
  };
  const handlechangeCode = (e) => {
    setUserCodesTeams(e.target.value);
    localStorage.setItem("userCodesTeams", e.target.value);
  };
  const handleVisibleForGames = (game) => {
    setGameSelected(game);
    setVisible(true);
  }
  const handleVisibleForLeagues = () => {
    setGameSelected(null);
    setVisible(true);
  }
  const openSuperLiniero = (game, indexGame) => {
    setGameSelected(game);
    setIndexGame(indexGame)
    setVisibleSuperLiniero(true);
  }
  const closeSuperLiniero = (game) => {
    console.log('CLOSING');
    setGameSelected(null);
    setVisibleSuperLiniero(false);
  }
  const handleClearChanges = async () => {
    try {
      await redApi.post(`/games/clear_changes?date=${date}&league=${league._id}`
      ).then((r) => r.data);
      await Swals.success(
        "Completado",
        "Los cambios se han limpiado"
      );
      await loadGames();
    } catch {
      await Swals.error(
        "Error",
        "Ha ocurrido un error al limpiar los cambios"
      );
    } finally {
      setLoading(false);
    }
  }
  const handleRecalTickets = async () => {
    try {
      await redApi.post(`/ticketsUpdate`).then((r) => {
      });
      await Swals.success(
        "Completado",
        "Se han actualizado los tickets"
      );
    } catch {
      await Swals.error(
        "Error",
        "Ha ocurrido un error al limpiar los cambios"
      );
    } finally {
      setLoading(false);
    }
  }
  const tableStatus = (status) => {
    let statusStr = '';
    switch (status) {
      case 'approved': statusStr = "Pendiente"; break;
      case 'playing': statusStr = "En Progreso"; break;
      case 'finished': statusStr = "Finalizado"; break;
      case 'canceled': statusStr = "Cancelado"; break;
      default: break;
    }
    return statusStr;
  }
  const handleTypeLineSelected = async (e) => {

    if (!league?.lineType[e].name) return;
    if (league?.lineType[e].name == 'Jugadores') {
      window.location.href = `/admin/horarioslineas/BPS?table=lineas`;

      return;
    }
    setTypeLineSelected(league.lineType[e])
    setPeriods(league.lineType[e]?.periods)
    league?.lineType[e].name == 'Ponches' ? setPoncheStatus(true) : setPoncheStatus(false)
    setTableData({ head: league?.lineType[e]?.periods[0]?.lines })
    setTypeLineSelected(league.lineType[e])
    setPeriods(league.lineType[e]?.periods)
    league?.lineType[e].name == 'Ponches' ? setPoncheStatus(true) : setPoncheStatus(false)
    setTableData({ head: league?.lineType[e]?.periods[0]?.lines })

  }

 
  if(isPonche){
    tableData.head.map((row, i)=> {
      if (i === 0) row.name = 'PONCHE'
      if (i === 1) row.name = 'PRECIO (+)'
      if (i === 2) row.name = 'PRECIO (-)'
    })
  }

  useEffect(() => {
    if (!league) return;
    setTableData({ head: league?.lineType[0]?.periods[0]?.lines })
  }, [league])

  const sendDataBots = async () => {
    try {
      const dataBots = await redApi.get('/chatbot/sendTimeBot')
      setDataTimeBot(dataBots.data)
    } catch (error) {
      console.log("error", error)
      throw error
    }
  } 

  const [historialScores, setHistorialScores] = useState([]);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [dateHistorial, setDateHistorial] = useState(getTodayDate())

  const handleDate = (e) => {
    setDateHistorial(e.target.value)
  }

  const getData = async () => {
    if(table === "scores" && league) {
      const alertLoading = Swal.fire({
        icon: "success",
        title: "Cargando Scores",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
      try {
        const data = await redApi.get(`/scores/sendHistorialScores?league=${league._id}&dateHistorial=${dateHistorial}`)
        setHistorialScores(data.data);
        alertLoading.close()
      } catch (error) {
        console.log("error sending historial scores", error);
      }
    }
  }

  useEffect(()=>{
    getData();
  },[table, dateHistorial])

  useEffect(()=>{
    sendDataBots()
  },[leagueConfig])

  const handlePeriodSelected = (index) => {
    setTableData({ head: typeLineSelected?.periods[index]?.lines })
  }
  const submitlinesByPeriods = async (value) => {
    let botName = selectedValue.split('/')[0]
    const bot = league.bots.find((bot) => bot.name == botName)
    let sportBook
    const swalLoading = Swal.fire({
      title: 'Procesando',
      text: 'Por favor, espera...',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
          Swal.showLoading(); // Muestra el spinner de carga
      }
  });
    if (bot.sportBooks.length !== 0) {
      let sportBookName = selectedValue.split('/')[1]
      sportBook = bot.sportBooks.find((sportBooks) => sportBooks.name == sportBookName)
    }
    let datasend = {
      ...linesByPeriods,
      action: value,
      leagues: [leagueSelected._id],
      bot: botName,
      sportBook
    }
    if (gameSelected !== null) {
   
      try {
        await redApi.post(`/linesByPeriods/${gameSelected._id}`, datasend)
        
        // Actualiza la alerta para indicar éxito
        swalLoading.close();
        Swal.fire('Completado', 'Los cambios se han guardado!!!', 'success');
        loadGames();
        setVisible(false);
    } catch (e) {
        // Actualiza la alerta para indicar error
        swalLoading.close();
        Swal.fire('Error', 'Ha ocurrido un error al guardar los cambios', 'error');
    }
    } else {
  
      try {
        await redApi.post(`/linesByPeriods`, datasend);
        // Actualiza la alerta para indicar éxito
        swalLoading.close();
        Swal.fire('Completado', 'Los cambios se han guardado!!!', 'success');
        loadGames();
        setVisible(false);
    } catch (e) {
        // Actualiza la alerta para indicar error
        swalLoading.close();
        Swal.fire('Error', 'Ha ocurrido un error al guardar los cambios', 'error');
    }
    }
  }
  const selectLineId = (id) => setLineIdSelected(id);

  const verifyOnChangeLines = (e, id, team, prop, game, force, newValue) => {
    let valueref = e.target.dataset.valueref;
    if (valueref && valueref[0] === "+") valueref = valueref.slice(1)
    const value = newValue ? newValue : e.target.value;
    const identifier = id + team + prop;
    if (valueref !== value || force) {
      if (!linesChanged.includes(identifier)) {
        setLinesChanged(prev => [...prev, identifier]);
      }
      setLinesChangedHashMap(prev => {
        prev.set(identifier, { value, id, team, prop });
        return prev;
      })
    } else {
      if (linesChanged.includes(identifier)) {
        setLinesChanged(prev => prev.filter(line => line !== identifier));
        setLinesChangedHashMap(prev => {
          prev.delete(identifier);
          return prev;
        })
      }
    }
    setForceRender(!forceRender)
  }

  const checkForm = (value, game) => {


  }
  const resetLinesChanges = () => {
    setLinesChanged([]);
    setLinesChangedHashMap(prev => { prev.clear(); return prev });
    setForceRender(!forceRender);
  }
  const sendLineChanges = async () => {
    if (linesChanged.length === 0) {
      Swal.fire({
        icon: "error",
        title: "No hay cambios para guardar",
        timer: 1500,
        showConfirmButton: false
      });
      return;
    }
    // setGamesTableLoading(true);
    const changes = Array.from(linesChangedHashMap.values());
    try {
      const { data } = await redApi.patch(`/updatemanylines`, { updatedLines: changes, matchUps: false });
      if (data.length > 0) {
        setGamesRender(prev => {
          const gamesModified = prev.map((game) => {
            const findInUpdated = data.find((gameUpdated) => gameUpdated._id.toString() === game._id.toString());
            if (findInUpdated) {
              return findInUpdated;
            }
            return game
          })
          return gamesModified;
        });
      }

      resetLinesChanges();
    } catch (error) {
      console.log(error);
      Swal.fire({ icon: "error", title: "Unexpected Error", timer: 1500, showConfirmButton: false });
    }
    // setGamesTableLoading(false);

    const inputSelected = document.querySelector(`[data-refid="${lineIdSelected}"]`);
    if (inputSelected) inputSelected.focus();
  }

  useEffect(() => {
    if (visible === true) {
      setVisibleSuperLiniero(false)
    }
  }, [visible])
  useEffect(() => {
    if (visibleSuperLiniero === true) {
      setVisible(false)
    }
  }, [visibleSuperLiniero])

  useEffect(() => {
    if (estatus) {
      changeStatusGames_(estatus)
    }
  }, [estatus])

  return {
    states: {
      // authLine,
      // authLineProp,
      // authLineProp2,
      // propiedad,
      // scoresToUpdate,
      allLeagues,
      automatic,
      date,
      estatus,
      fetchLoading,
      gameSelected,
      indexGame,
      gamesRender,
      gamesTableLoading,
      gameToUpdate,
      historialRender,
      isPonche,
      league,
      leagueConfig,
      leagueSelected,
      lineIdSelected,
      linesByPeriods,
      lineToEdit,
      loading,
      loadingMessage,
      modalUpdateGame,
      periods,
      scoreStructure,
      selectedValue,
      showModalCreate,
      tableData,
      teamsDB,
      typeLineSelected,
      userCodesTeams,
      visible,
      linesChanged,
      // setAuthLine, 
      linesChangedHashMap,
      visibleSuperLiniero,
      dataTimeBot,
      dataBot,
      historialScores,
      dateHistorial,
      typeLineHistorial,
      searchUser,
      sectedNameLeague
    },
    setStates: {
      // setAllLeagues, 
      // setAuthLineProp, 
      // setAuthLineProp2, 
      // setAutomatic, 
      // setFetchLoading, 
      // setGameSelected, 
      // setGameToUpdate, 
      // setHistorialRender, 
      // setLeague, 
      // setLeagueConfig, 
      // setLoading, 
      // setLoadingMessage, 
      // setModalUpdateGame, 
      // setPoncheStatus, 
      // setPropiedad, 
      // setScoresToUpdate, 
      // setScoreStructure, 
      // setTeamsDB, 
      setDate,
      setEstatus,
      setGamesRender,
      setLeagueSelected,
      setLinesByPeriods,
      setLineToEdit,
      setPeriods,
      setSelectedValue,
      setShowModalCreate,
      setTableData,
      setTypeLineSelected,
      setUserCodesTeams,
      setVisible,
      setLeague,
      setDataTimeBot,
      setDataBot,
    },
    methods: {
      approveGames,
      approveGamesLeague,
      getLeague,
      getTeams,
      handleAuthLine,
      handleAutomatic,
      handleCancelModal,
      handleChange,
      handlechangeCode,
      handleChangeLeague,
      autorizeLineChange,
      handleChangeLeagueLines,
      handleChangeScores,
      handleClearChanges,
      handlePeriodSelected,
      handleReCalcScores,
      handleRecalTickets,
      handleShowModalSetScores,
      handleStatusGame,
      handleTypeLineSelected,
      handleUpdateGame,
      handleUpdateScores,
      handleVisibleForGames,
      handleVisibleForLeagues,
      loadGames,
      putAuthLine,
      putData,
      putUnauthLine,
      selectLineId,
      submitlinesByPeriods,
      syncLines,
      syncScores,
      tableStatus,
      getTeamsLeague,
      verifyOnChangeLines,
      sendLineChanges,
      openSuperLiniero,
      closeSuperLiniero,
      sendDataBots,
      getData,
      handleDate,
      handleTypeLineHistorial,
      handleSearchUser
    }
  }
}


