import React, { useState, useEffect } from 'react'
import { redApi } from '../../../config/Axios';
import { Modal } from 'antd'
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Printer } from '../../printer/Print';
import { AuthContext } from '../../../auth';
import { redApi as api } from '../../../config/Axios';
import moment from 'moment';
import ReportesModal from './reportes/ReportesModal';

export default function Footer({ mobile }) {

  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  }

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get('/verify')
      .then(async (res) => {
        if (res.data) {
          await redApi.put(`/users/changeTheme/${res.data._id}`, { theme: userTheme })
            .then(res => {
              Swal.fire({
                icon: 'success',
                title: 'Theme changed',
                showConfirmButton: false,
                timer: 1500
              })
              window.location.reload();
            })
        }
      })
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  }


  const { logout, userTheme: themeofuser } = useContext(AuthContext);

  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate('/', {
      replace: true
    });
  }

  const [showReports, setShowReports] = useState(false)
  const [tickets, setTickets] = useState([])
  const [inLoading, setInLoading] = useState(false)
  const [reportsType, setReportsType] = useState('')
  const handleReports = (show) => {
    setShowReports(!showReports)
    setReportsType(show)
  }

  useEffect(() => {
    if (showReports) {
      setInLoading(true)
      let initDate = moment().format('YYYY-MM-DD')
      let finalDate = moment().format('YYYY-MM-DD') + 'T23:59:59'
      api.get(`/tickets?initDate=${initDate}&finalDate=${finalDate}`) //today tickets
        .then(res => {
          let tickets = res.data.filter(ticket => {
            if (reportsType === 'reports') return ticket
            if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
            if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
            if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
          })
          setTickets(tickets)
          setInLoading(false)
        })
        .catch(err => console.log(err))
    }
  }, [showReports])

  const [printTicket, setPrintTicket] = useState({
    ticketId: 0,
    print: false
  });
  const handleRePrint = (ticketId) => {
    setPrintTicket({ ticketId, print: true })
  }

  const handleCancelTicket = (ticketId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        api.put(`/tickets/${ticketId}/cancel`)
          .then(res => {
            if (res.status === 200) {
              Swal.fire(
                'Canceled!',
                'Your ticket has been canceled.',
                'success'
              )
              // change ticket status 
              let newTickets = tickets.map(ticket => {
                if (ticket.ticketId === ticketId) {
                  ticket.status = 'Cancelled'
                  return ticket
                }
                return ticket
              })
              setTickets(newTickets)
              return
            }
          })
          .catch((err) => {
            console.log(err.response.data.message, 'err')
            Swal.fire(
              'Error!',
              `${err.response.data.message || 'Something went wrong'}`,
              'error',

            )
          })
      }
    })
  }


  // help modal 
  const [showHelp, setShowHelp] = useState(false)
  const hadnleshowHelp = () => {
    setShowHelp(!showHelp)
  }

  let helpTexts = [
    { key: 'm', text: 'Money Line' },
    { key: 'mh', text: 'Money Line H1' },
    { key: 'mt1', text: 'Money Line P1' },
    { key: 'mt2', text: 'Money Line P2' },
    { key: 'mt3', text: 'Money Line P3' },
    { key: 'r', text: 'Run Line' },
    { key: 'h', text: 'Run Line H' },
    { key: 'q1', text: 'Run Line Q1' },
    { key: 'q2', text: 'Run Line Q2' },
    { key: 'q3', text: 'Run Line Q3' },
    { key: 'q4', text: 'Run Line Q4' },
    { key: 't1', text: 'Run Line P1' },
    { key: 't2', text: 'Run Line P2' },
    { key: 't3', text: 'Run Line P3' },
    { key: 'hr2', text: 'Run Line H2' },
    { key: '+', text: 'Over' },
    { key: 'h+', text: 'Over H' },
    { key: 'q1+', text: 'Over Q1' },
    { key: 'q2+', text: 'Over Q2' },
    { key: 'q3+', text: 'Over Q3' },
    { key: 'q4+', text: 'Over Q4' },
    { key: 't1+', text: 'Over P1' },
    { key: 't2+', text: 'Over P2' },
    { key: 't3+', text: 'Over P3' },
    { key: 'h2+', text: 'Over H2' },
    { key: '-', text: 'Under' },
    { key: 'h-', text: 'Under H' },
    { key: 'q1-', text: 'Under Q1' },
    { key: 'q2-', text: 'Under Q2' },
    { key: 'q3-', text: 'Under Q3' },
    { key: 'q4-', text: 'Under Q4' },
    { key: 't1-', text: 'Under P1' },
    { key: 't2-', text: 'Under P2' },
    { key: 't3-', text: 'Under P3' },
    { key: 'h2-', text: 'Under H2' },
    { key: 's+', text: 'Solo +' },
    { key: 'h1s+', text: 'Solo +H' },
    { key: 'q1s+', text: 'Solo +Q1' },
    { key: 'q2s+', text: 'Solo +Q2' },
    { key: 'q3s+', text: 'Solo +Q3' },
    { key: 'q4s+', text: 'Solo +Q4' },
    { key: 'h2s+', text: 'Solo +H' },
    { key: 's-', text: 'Solo -' },
    { key: 'h1s-', text: 'Solo -H' },
    { key: 'q1s-', text: 'Solo -Q1' },
    { key: 'q2s-', text: 'Solo -Q2' },
    { key: 'q3s-', text: 'Solo -Q3' },
    { key: 'q4s-', text: 'Solo -Q4' },
    { key: 'h2s-', text: 'Solo -H' },
    { key: 'p', text: 'Super Run Line' },
    { key: 'y', text: 'Yes anotaran' },
    { key: 'n', text: 'No anotaran' },
    { key: 'k+', text: 'Strikeout +' },
    { key: 'k-', text: 'Strikeout -' },
    { key: 'f', text: 'First run' },
    { key: 'c', text: 'Buying' },
    { key: 'v', text: 'Senta' },
    { key: 'c+', text: 'B Over' },
    { key: 'c-', text: 'B Under' },
    { key: 'j1', text: 'Players 1' },
    { key: 'j2', text: 'Players 2' },
    { key: 'j3', text: 'Players 3' },
    { key: 'j4', text: 'Players 4' },
    { key: 'j5', text: 'Players 5' },
    { key: 'g', text: 'GS ML' },
    { key: 'g+', text: 'GS Over' },
    { key: 'g-', text: 'GS Under' },
    { key: 'se', text: 'Serie' },
    { key: 'ar', text: 'Run Line Alternativo' },
    { key: 't', text: 'Tercio' },
    { key: 't+', text: 'Tercio Mas' },
    { key: 't-', text: 'Tercio Menos' },
    { key: 'v+', text: 'S a mas' },
    { key: 'v-', text: 'S a menos' },
    { key: 'e', text: 'Draw' },
    { key: 'hr1', text: 'Run Line H' },
    { key: 'h1+', text: 'Over H' },
    { key: 'h1-', text: 'Under H' }
  ]


  const HelpModal = () => {
    return (
      <Modal
        open={showHelp}
        onCancel={hadnleshowHelp}
        footer={null}
      >
        <div className="row">
          <div className="col">
            <h3>How to use the system</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Enter</th>
                  <th scope="col">A Juego</th>
                </tr>
              </thead>
              <tbody>
                {
                  helpTexts.map((helpText, index) => (
                    <tr key={index}>
                      <td>{helpText.key}</td>
                      <td>{helpText.text}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    )
  }

  // date filters
  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: ''
  })

  // get tickets if filters change
  const handleGetTickets = async () => {
    let { fromDate, toDate } = filters
    // if toDate is today, add 24 hours to get all tickets
    if (toDate === moment().format('YYYY-MM-DD')) {
      toDate = moment().add(1, 'days').format('YYYY-MM-DD')
    }

    setInLoading(true)
    await api.get(`/tickets?initDate=${fromDate}&finalDate=${toDate}`)
      .then(res => {
        let tickets = res.data.filter(ticket => {
          if (reportsType === 'reports') return ticket
          if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
          if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
          if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
        })
        setTickets(tickets)
        setInLoading(false)
      })
      .catch(err => {
        console.log(err)
      }
      )
  }
  const [reportsModalOpen, setReportsModalOpen] = useState(false)
  const handleReportsModal = () => {
    setReportsModalOpen(!reportsModalOpen)
  }
  return (
    <footer id="footer">
      <ReportesModal
        open={reportsModalOpen}
        onCancel={handleReportsModal}
      />
      {
        printTicket.print ?

          <Printer ticketId={printTicket.ticketId} printTicket={printTicket} setPrintTicket={setPrintTicket} />
          : null
      }
      <HelpModal />
      <div className='col-12 d-flex justify-content-center p-0 align-items-center'>
        <ul style={{ listStyle: "none" }} className="d-flex justify-content-center align-items-center">
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} onClick={() => handleReports('cancelticket')}>Anular</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} onClick={() => handleReports('reprint')}>Reimprimir</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} disabled>Salvar</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} disabled>Texto</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} disabled>Imprimir</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} onClick={() => handleReports('salesResults')}>Result de venta</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} onClick={() => handleReportsModal()}>Reportes</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }} onClick={hadnleshowHelp}>Ayuda</button></li>
          <li className='col'><button type='button' className='btn' style={{ fontSize: '14px' }}
            onClick={showModal}
          ><i className="fas fa-adjust px-1"></i>
            change theme</button></li>
          <li className='col'> <button type='button' style={{ fontSize: '14px' }} className="btn d-flex align-items-center justify-content-center gap-1" onClick={onLogout}>
            <i className="fas fa-sign-out-alt"></i>
            Exit
          </button></li>
          <Modal
            title="Change Theme"
            open={isModalVisible}
            onCancel={handleCancel}
            width={'30%'}
            onOk={handleOk}
          >
            <div className="d-flex flex-column justify-content-center align-items-center py-2">
              <i className="fas fa-adjust px-1 h1"></i>
            </div>
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <select
                defaultValue={themeofuser || 0}
                className="form-select col-6"
                aria-label="Default select example"
                onChange={handleChangeTheme}
              >
                <option disabled value={0}>Choose theme</option>
                <option value="1" selected={themeofuser === 1} disabled={themeofuser === 1}>Legacy</option>
                <option value="2" selected={themeofuser === 1} disabled={themeofuser === 2}>Red</option>
                <option value="3" selected={themeofuser === 1} disabled={themeofuser === 3}>Azul</option>
                <option value="4" selected={themeofuser === 1} disabled={themeofuser === 4}>Theme 4</option>
              </select>
            </div>
          </Modal>
        </ul>
      </div>
      <Modal
        open={showReports}
        onCancel={handleReports}
        footer={null}
        width={mobile ? '100vw' : '80%'}
        className='modal_reports'
      >
        {
          mobile ? (
            <div className="row" id='reportsmodalsimple'>
              <div className="col-12 d-flex justify-content-center flex-column align-items-center gap-4">
                {/* date filters */}
                <div className="d-flex gap-4 align-items-end justify-content-center">
                  <div className="col">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" name="fromDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                  </div>
                  <div className="col hide_mobile">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" name="toDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                  </div>
                  <div className="col">
                    <button className="btn btn-primary" onClick={handleGetTickets}>Get Tickets</button>
                  </div>
                </div>
                <table className="table-sm">
                  <thead>
                    <tr>
                      {/* <th scope="col">Consorcio</th> */}
                      <th scope="col" className='text-center'>Ticket #</th>
                      {
                        reportsType !== 'cancelticket' ? <th scope="col" className='text-center'>Fecha/Hora</th> : null
                      }

                      {
                        reportsType === 'salesResults' || reportsType === 'cancelticket' ? null : <th scope="col" className='text-center'>POS</th>
                      }
                      {/* <th scope="col" className='text-center'>Usuario</th> */}
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>Tipo de Ticket</th>
                      }

                      <th scope="col" className='text-center'>Apostado</th>
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>A pagar</th>
                      }
                      {
                        reportsType !== 'cancelticket' ? <th scope="col" className='text-center'>Status</th> : null
                      }
                      {
                        reportsType === 'cancelticket' ? <th scope="col" className='text-center'>Cancelar</th> : null
                      }

                    </tr>
                  </thead>
                  <tbody className='position-relative'>
                    {
                      inLoading ? (
                        <tr
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '30px',
                            height: '30px'
                          }}>
                          <td className="visually-hidden text-primary">Loading...</td>
                        </tr>
                      ) : (
                        tickets.length === 0 ? (
                          <tr>
                            <td colSpan={7} className='text-center'>No hay tickets</td>
                          </tr>
                        ) : (
                          tickets.map((ticket, index) => (
                            <tr key={index} onClick={reportsType === 'reprint' ? () => handleRePrint(ticket.ticketId) : null}>
                              {/* <td>{ticket.consortium || 'N/A'}</td> */}
                              <td className='text-center'>#{ticket.ticketId || 'N/A'}</td>
                              {
                                reportsType !== 'cancelticket' ? <td className='text-center'>{moment(ticket.createdAt).format('DD/MM/YYYY hh:mm A')}</td> : null
                              }
                              {
                                reportsType === 'salesResults' || reportsType === 'cancelticket' ? null : <td className='text-center'>{ticket.createdBy.username || 'N/A'}</td>
                              }

                              {/* <td className='text-center'>{ticket.user || 'N/A'}</td> */}
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.bets.length === 1 ? 'Direct' : ticket.bets.length === 2 ? 'Pale' : ticket.bets.length === 3 ? 'Tripleta' : ticket.bets.length === 4 ? 'Cuadruple' : ticket.bets.length === 5 ? 'Quintuple' : ticket.bets.length === 6 ? 'Sextuple' : ticket.bets.length === 7 ? 'Septuple' : ticket.bets.length === 8 ? 'Octuple' : ticket.bets.length === 9 ? 'Nonuple' : ticket.bets.length === 10 ? 'Decuple' : 'N/A'}</td>
                              }
                              <td className='text-center'>${ticket.amount || 'N/A'}</td>
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>${ticket.toCollect || 'N/A'}</td>
                              }
                              {
                                reportsType !== 'cancelticket' ? <td className={`text-center ${ticket.ticketState === 'Play' ? 'text-warning' : ticket.ticketState === 'Winner' ? 'text-success' : ticket.ticketState === 'Loser' ? 'text-danger' : 'text-secondary'}`}
                                >{ticket.ticketState || 'N/A'}</td> : null
                              }
                              {
                                reportsType === 'cancelticket' ? <td className='text-center'><button className="btn btn-danger btn-sm" onClick={() => handleCancelTicket(ticket.ticketId)}>Cancelar</button></td> : null
                              }
                            </tr>
                          ))
                        )
                      )
                    }
                  </tbody>
                  {
                    reportsType !== 'reports' ? null : (
                      <tfoot>
                        <tr>
                          <td colSpan={4} className='text-center'>Total</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0)}</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0) - tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                        </tr>
                      </tfoot>
                    )
                  }
                </table>
              </div>
            </div>
          ) : (
            <div className="row" id='reportsmodalsimple'>
              <div className="col-12 d-flex justify-content-center flex-column align-items-center gap-4">
                {/* date filters */}
                <div className="d-flex gap-4 align-items-end justify-content-center">
                  <div className="col">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" name="fromDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                  </div>
                  <div className="col hide_mobile">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" name="toDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                  </div>
                  <div className="col">
                    <button className="btn btn-primary" onClick={handleGetTickets}>Get Tickets</button>
                  </div>
                </div>
                <table className="table-sm">
                  <thead>
                    <tr>
                      {/* <th scope="col">Consorcio</th> */}
                      <th scope="col" className='text-center'>Ticket #</th>
                      <th scope="col" className='text-center'>Fecha/Hora</th>
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>POS</th>
                      }
                      {/* <th scope="col" className='text-center'>Usuario</th> */}
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>Tipo de Ticket</th>
                      }

                      <th scope="col" className='text-center'>Apostado</th>
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>A pagar</th>
                      }
                      <th scope="col" className='text-center'>Status</th>
                    </tr>
                  </thead>
                  <tbody className='position-relative'>
                    {
                      inLoading ? (
                        <tr className="spinner-border text-primary position-absolute top-50 start-50" role="status">
                          <td colSpan={7} className="visually-hidden">Loading...</td>
                        </tr>
                      ) : (
                        tickets.length === 0 ? (
                          <tr>
                            <td colSpan={7} className='text-center'>No hay tickets</td>
                          </tr>
                        ) : (
                          tickets.map((ticket, index) => (

                            <tr key={index} onClick={reportsType === 'reprint' ? () => handleRePrint(ticket.ticketId) : reportsType === 'cancelticket' ? () => handleCancelTicket(ticket.ticketId) : null}>
                              {/* <td>{ticket.consortium || 'N/A'}</td> */}
                              <td className='text-center'>#{ticket.ticketId || 'N/A'}</td>
                              <td className='text-center'>{moment(ticket.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.createdBy.username || 'N/A'}</td>
                              }

                              {/* <td className='text-center'>{ticket.user || 'N/A'}</td> */}
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.bets.length === 1 ? 'Direct' : ticket.bets.length === 2 ? 'Pale' : ticket.bets.length === 3 ? 'Tripleta' : ticket.bets.length === 4 ? 'Cuadruple' : ticket.bets.length === 5 ? 'Quintuple' : ticket.bets.length === 6 ? 'Sextuple' : ticket.bets.length === 7 ? 'Septuple' : ticket.bets.length === 8 ? 'Octuple' : ticket.bets.length === 9 ? 'Nonuple' : ticket.bets.length === 10 ? 'Decuple' : 'N/A'}</td>
                              }
                              <td className='text-center'>${ticket.amount || 'N/A'}</td>
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>${ticket.toCollect || 'N/A'}</td>
                              }
                              <td className={`text-center ${ticket.ticketState === 'Play' ? 'text-warning' : ticket.ticketState === 'Winner' ? 'text-success' : ticket.ticketState === 'Loser' ? 'text-danger' : 'text-secondary'}`}
                              >{ticket.ticketState || 'N/A'}</td>
                            </tr>
                          ))
                        )
                      )
                    }
                  </tbody>
                  {
                    reportsType !== 'reports' ? null : (
                      <tfoot>
                        <tr>
                          <td colSpan={4} className='text-center'>Total</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0)}</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0) - tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                        </tr>
                      </tfoot>
                    )
                  }

                </table>
              </div>
            </div>
          )
        }

      </Modal>
    </footer>
  )
}
