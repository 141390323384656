
import react, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import { AuthContext } from "../../../auth";
import "./sidebarNew.scss";
import { UserServices } from "../../../services/userServices";
import validPrivileges from "../../../utils/validatePriv";
import { HyRContext } from "../../../HyLUtils/context/HyLContext";
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import svgIcon from '../../../assets/logos/Group1.svg'
import svgIcon2 from '../../../assets/logos/Group2.svg'
import Chat from "./chatBot/ChatbotComponent";
import svgMenu from '../../../assets/menu-svgrepo-com.svg'
import { socket } from "../../../socket";
import consorcio1 from "../../../assets/consorcio_img/consorcio1.png"
import consorcio2 from "../../../assets/consorcio_img/consorcio2.png"
import consorcio3 from "../../../assets/consorcio_img/consorcio3.png"

let privilegesRequired = {
    hyr: 7,
}


const CustomNavLink = ({ to, children }) => {
    const location = useLocation();
    // Extraer el valor del parámetro 'tab' de la URL
    const tab = new URLSearchParams(location.search).get('tab');
    // Verificar si el enlace activo coincide con el enlace actual y el parámetro 'tab'
    const isActive = location.pathname === to && to.includes(tab);

    return (
        <NavLink
            to={to}
            className={`nav-link ${isActive ? "active" : ""}`}
        >
            {children}
        </NavLink>
    );
};

function SidebarNew() {
    const { logout, userLevel, privileges, userName } = useContext(AuthContext);
    const [statusBot, setStatusBot] = useState(false);
    const [ticketCode, setTicketCode] = useState();
    const [SlackTimestamp, setSlackTimestamp] = useState('');
    let messageTimestamp
    const [issueReport, setIssueReport] = useState('');
    const [reportSelect, setReportSelect] = useState();

    const [isExpanded, setIsExpanded] = useState(true);
    const [showMenu, setShowMenu] = useState(true)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    
    const location = useLocation();

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };


    const navigate = useNavigate();
    const onLogout = () => {
        logout();
        navigate("/", { replace: true });
    };

    const openChatBot = () => {
        setStatusBot(!statusBot)
    };

    // Definir los estilos inline
    const styles = {
        label: {
            display: "block",
            margin: "10px 0",
        },
        input: {
            width: "300px",
            padding: "5px",
        },
        textarea: {
            width: "300px",
            height: "100px",
            padding: "5px",
        },
        button: {
            display: "block",
            margin: "20px 0",
            padding: "10px 20px",
            backgroundColor: "blue",
            color: "white",
            border: "none",
            cursor: "pointer",
        },
    };

    const [menuSidebar, setMenuSidebar] = useState([]);
    const [loadedPrivileges, setLoadedPrivileges] = useState(false);
    const [userPrivileges, setUserPrivileges] = useState([]);
    
    useEffect(() => {
        (async () => {
            const userPriv = await UserServices.getUserPrivileges('General', privileges);
            if (userPriv) setUserPrivileges(userPriv.values);
            setLoadedPrivileges(true);
        })();
    }, []);
    useEffect(() => {
    }, [ticketCode]);


    useEffect(() => {
        // console.log({userPrivileges})
        if (!loadedPrivileges) return;


        const allmenu = [
            {
                name: "Inicio",
                icon: "fas fa-home",
                link: "/",
            },
            {
                name: "Horarios y Lineas",
                icon: "fas fa-calendar-alt",
                link: "/admin/horarioslineas/MLB?table=lineas",
                priv: [7]
            },
            {
                name: "Usuarios & Grupos",
                icon: "fas fa-user-friends",
                link: "/admin/configuracion?tab=usuarios",
                priv: [22, 26]
            },
            {
                name: "Grupo de Reportes",
                icon: "fas fa-chart-bar",
                link: "/admin/group-reports"
            },
            {
                name: "Terminales",
                icon: "fas fa-desktop",
                link: "/admin/configuracion?tab=terminales",
                priv: [18]
            },
            {
                name: "Reportes",
                icon: "fas fa-chart-pie",
                link: "/admin/reports",
                priv: [17]
            },
            {
                name: "Premios",
                icon: "fas fa-trophy",
                link: "/admin/prizes",
            },
            {
                name: "Ingreso/Egreso",
                icon: "fas fa-dollar-sign",
                link: "/admin/accounting",
                priv: [12]
            },
            {
                name: "Configuración",
                icon: "fas fa-cog",
                // link: "/admin/configuracion",
                link: "/admin/configuracion?tab=consorcio",
                priv: [14]
            },
            {
                name: "Mensajes",
                icon: "fas fa-envelope",
                link: "/admin/messages",
            },
            {
                name: "Venta Admin",
                icon: "fas fa-shopping-cart",
                link: "/admin/venta-admin",
                priv: [9]

            },
            {
                name: "Monitor",
                icon: "fas fa-tv",
                link: "/admin/monitor",
            },
            {
                name: "Web Users",
                icon: "fas fa-user-cog",
                link: "/admin/webusers",
            },
            {
                name: "Tools",
                icon: "fas fa-tools",
                link: "/admin/tools",
            },
            {
                name: "WhatsApp",
                icon: "fa-brands fa-whatsapp",
                link: "/admin/whatsapp",
            },
            {
                name: "limitXconsorcio",
                icon: "fas fa-times",
                link: "/admin/limit-consorcio"
            },

        ]

        if (userLevel === 10) {
            //* ES LINEMAN
            // const lineManMenu = [
            //     {
            //         name: "Horarios y Lineas",
            //         icon: "fas fa-calendar-alt",
            //         link: "/admin/horarioslineas/MLB?table=lineas",
            //     },
            // ]
            // setMenuSidebar(lineManMenu);
            let userMenu = []
            for (const menu of allmenu) {
                if (!menu.priv) continue

                const valid = validPrivileges(userPrivileges, menu.priv);
                if (valid === true) userMenu.push(menu)
            }
            setMenuSidebar(userMenu);

            return;
        }

        const valid = validPrivileges(userPrivileges, [privilegesRequired.hyr]);
        // console.log({valid})
        if (userLevel === 1 || valid === true) {
            setMenuSidebar(allmenu);
        } else if (userLevel === 2) {
            const menu = allmenu.filter((item) => {
                return !['Horarios y Lineas', 'Tools', 'WhatsApp', 'Configuración'].includes(item.name)
            })
            setMenuSidebar(menu);

        } else if (userLevel === 5) {
            setMenuSidebar([{
                name: "Support",
                icon: "fa fa-ambulance",
                link: "/admin/support",
            }]);

        }
    }, [userPrivileges.length, userLevel, loadedPrivileges]);

    useEffect(async () => {
        messageTimestamp = SlackTimestamp
        console.log(SlackTimestamp, 'this this this', messageTimestamp);
    }, [SlackTimestamp])


    // useEffect(() => {
    //     window.addEventListener('resize', handleResize);

    //     // Limpia el evento al desmontar el componente
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    //   }, []);

    // Actualiza showMenu según el ancho de la pantalla
    //   useEffect(() => {
    //     setShowMenu(windowWidth > 767); // Ajusta el valor según tus necesidades
    //   }, [windowWidth]);


    const theme = {
        background: '#f5f8fb',
        fontFamily: 'Roboto',
        headerBgColor: '#cc2f48',
        headerFontColor: '#fff',
        headerFontSize: '15px',
        botBubbleColor: '#ACACAC',
        botFontColor: 'black',
        userBubbleColor: '#7914d7',
        userFontColor: '#fff',
    };

    const isTabActive = (link) => {
        const tabValue = link.split("=")[1];
        const fullPath = `${location.pathname}${location.search}`;
        const pathParts = fullPath.split("=")

        let isActive;
        if (tabValue !== undefined) {
            isActive = tabValue === pathParts[1]
            return isActive
        }
        else {
            isActive = link == fullPath
            return isActive
        }
    };




    return (
        <div className={`sidebar-container ${showMenu ? 'menu-expanded' : 'menu-collapsed'}`}>
            {/* {
                showMenu  ?
              ( */}
            <div id="sidebar" className="sidebar">
                <div className="sidebar__logo">
                    {/* <img src="/sports_favicon.png" alt="logo" className="sidebar__logo-img" /> */}
                    <img key={userName} src={userName === "boston" ? consorcio1 : userName === "caracas" ? consorcio2 : "/sports_favicon.png"} alt="logo" className="sidebar__logo-img" />
                </div>
                <div className="sidebar__menu">
                    <ul>
                        {
                            menuSidebar.map((item, index) => {

                                return (

                                    <li key={index} onClick={() => { }}>
                                        <Link exact="true" to={item.link} className={`sidebar__link ${isTabActive(item.link) ? "active" : ""}`} >
                                            <i className={item.icon}></i>
                                            <span>{item.name}</span>
                                        </Link>
                                    </li>



                                )
                            }
                            )
                        }
                    </ul>
                    <div className="sidebar__bot" onClick={openChatBot}>
                        <i className="fas fa-robot" style={{ marginLeft: '17px' }} ></i>
                        <span>ChatBot</span>
                    </div>
                    <div className="sidebar__logout" onClick={onLogout}>
                        <i className="fas fa-power-off" style={{ marginLeft: '17px' }}></i>
                        <span>Logout</span>
                    </div>
                </div>
            </div>

            {/* ) */}
            {/* //   :
            //   (
            //     <img className={`svgMenu ${showMenu ? 'show' : 'hide'}`} src={svgMenu} alt="menu" onClick={() => setShowMenu(!showMenu)} />
            //   )           */}

            {/* } */}



            {statusBot &&

                <div id="chatBot">

                    <Chat botImg={svgIcon} />
                </div>
            }
        </div>

    )
}

export default SidebarNew