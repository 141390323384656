export const valuesCheck = {
    moneyLine: {
        "100": { counter: { moneyLine: '-120', gavela: '-1.5', price: '+175' }, self: { gavela: '+1.5', price: '-210' } },
        "105": { counter: { moneyLine: '-125', } },
        "110": { counter: { moneyLine: '-130', gavela: '-1.5', price: '+165' }, self: { gavela: '+1.5', price: '-200' } },
        "115": { counter: { moneyLine: '-135', gavela: '-1.5', price: '+175' }, self: { gavela: '+1.5', price: '-205' } },
        "120": { counter: { moneyLine: '-140', gavela: '-1.5', price: '+160' }, self: { gavela: '+1.5', price: '-180' } },
        "125": { counter: { moneyLine: '-145', gavela: '-1.5', price: '+145' }, self: { gavela: '+1.5', price: '-165' } },
        "130": { counter: { moneyLine: '-150', gavela: '-1.5', price: '+140' }, self: { gavela: '+1.5', price: '-160' } },
        "135": { counter: { moneyLine: '-155', gavela: '-1.5', price: '+135' }, self: { gavela: '+1.5', price: '-155' } },
        "140": { counter: { moneyLine: '-160', gavela: '-1.5', price: '+130' }, self: { gavela: '+1.5', price: '-150' } },
        "145": { counter: { moneyLine: '-165', gavela: '-1.5', price: '+125' }, self: { gavela: '+1.5', price: '-145' } },
        "150": { counter: { moneyLine: '-170', gavela: '-1.5', price: '+120' }, self: { gavela: '+1.5', price: '-140' } },
        "155": { counter: { moneyLine: '-175', gavela: '-1.5', price: '+115' }, self: { gavela: '+1.5', price: '-135' } },
        "160": { counter: { moneyLine: '-180', gavela: '-1.5', price: '+110' }, self: { gavela: '+1.5', price: '-130' } },
        "165": { counter: { moneyLine: '-185', gavela: '-1.5', price: '+105' }, self: { gavela: '+1.5', price: '-125' } },
        "170": { counter: { moneyLine: '-200', } },
        "175": { counter: { moneyLine: '-210', } },
        "175": { counter: { moneyLine: '-220', } },
        "180": { counter: { moneyLine: '-220', } },
        "185": { counter: { moneyLine: '-230', } },
        "200": { counter: { moneyLine: '-240', } },
        "205": { counter: { moneyLine: '-245', } },
        "210": { counter: { moneyLine: '-250', } },
        "215": { counter: { moneyLine: '-255', } },
        "220": { counter: { moneyLine: '-260', } },
        "225": { counter: { moneyLine: '-265', } },
        "230": { counter: { moneyLine: '-270', } },
        "240": { counter: { moneyLine: '-285', } },
        "255": { counter: { moneyLine: '-310', } },
        "260": { counter: { moneyLine: '-320', } },
        "-110": { counter: { moneyLine: '-110', gavela: '+1.5', price: '+180' }, self: { gavela: '-1.5', price: '-220' } },
        "-120": { counter: { moneyLine: "+100", gavela: '+1.5', price: '-210' }, self: { gavela: '-1.5', price: '+175' } },
        "-125": { counter: { moneyLine: "+105", } },
        "-130": { counter: { moneyLine: "+110", gavela: '+1.5', price: '-200' }, self: { gavela: '-1.5', price: '+165' } },
        "-135": { counter: { moneyLine: "+115", gavela: '+1.5', price: '-205' }, self: { gavela: '-1.5', price: '+165' } },
        "-140": { counter: { moneyLine: "+120", gavela: '+1.5', price: '-180' }, self: { gavela: '-1.5', price: '+160' } },
        "-145": { counter: { moneyLine: "+125", gavela: '+1.5', price: '-165' }, self: { gavela: '-1.5', price: '+145' } },
        "-150": { counter: { moneyLine: "+130", gavela: '+1.5', price: '-160' }, self: { gavela: '-1.5', price: '+140' } },
        "-155": { counter: { moneyLine: "+135", gavela: '+1.5', price: '-155' }, self: { gavela: '-1.5', price: '+135' } },
        "-160": { counter: { moneyLine: "+140", gavela: '+1.5', price: '-150' }, self: { gavela: '-1.5', price: '+130' } },
        "-165": { counter: { moneyLine: "+145", gavela: '+1.5', price: '-145' }, self: { gavela: '-1.5', price: '+125' } },
        "-170": { counter: { moneyLine: "+150", gavela: '+1.5', price: '-140' }, self: { gavela: '-1.5', price: '+120' } },
        "-175": { counter: { moneyLine: "+155", gavela: '+1.5', price: '-135' }, self: { gavela: '-1.5', price: '+125' } },
        "-180": { counter: { moneyLine: "+160", gavela: '+1.5', price: '-130' }, self: { gavela: '-1.5', price: '+110' } },
        "-185": { counter: { moneyLine: "+165", gavela: '+1.5', price: '-125' }, self: { gavela: '-1.5', price: '+105' } },
        "-200": { counter: { moneyLine: "+170" } },
        "-220": { counter: { moneyLine: "+180" } },
        "-230": { counter: { moneyLine: "+185" } },
        "-240": { counter: { moneyLine: "+200" } },
        "-245": { counter: { moneyLine: "+205" } },
        "-250": { counter: { moneyLine: "+210" } },
        "-255": { counter: { moneyLine: "+215" } },
        "-260": { counter: { moneyLine: "+220" } },
        "-265": { counter: { moneyLine: "+225" } },
        "-270": { counter: { moneyLine: "+230" } },
        "-285": { counter: { moneyLine: "+240" } },
        "-310": { counter: { moneyLine: "+255" } },
        "-320": { counter: { moneyLine: "+260" } },
    },
    posMinus: {
        "-110": { counter: { posPlus: "-110", posMinus: '-110' }, self: { posPlus: "-110" } },
        "100": { counter: { posPlus: "-120", posMinus: '+100' }, self: { posPlus: "-120" } },
        "105": { counter: { posPlus: "-125", posMinus: '+105' }, self: { posPlus: "-125" } },
        "110": { counter: { posPlus: "-130", posMinus: '+110' }, self: { posPlus: "-130" } },
        "115": { counter: { posPlus: "-135", posMinus: '+115' }, self: { posPlus: "-135" } },
        "120": { counter: { posPlus: "-140", posMinus: '+120' }, self: { posPlus: "-140" } },
        "-120": { counter: { posPlus: "100", posMinus: '-120' }, self: { posPlus: "100" } },
        "-125": { counter: { posPlus: "105", posMinus: '-125' }, self: { posPlus: "105" } },
        "-130": { counter: { posPlus: "110", posMinus: '-130' }, self: { posPlus: "110" } },
        "-135": { counter: { posPlus: "115", posMinus: '-135' }, self: { posPlus: "115" } },
        "-140": { counter: { posPlus: "120", posMinus: '-140' }, self: { posPlus: "120" } },
    },

    posPlus: {
        "-110": { counter: { posMinus: "-110", posPlus: '-110' }, self: { posMinus: "-110" } },
        "100": { counter: { posMinus: "-120", posPlus: '+100' }, self: { posMinus: "-120" } },
        "105": { counter: { posMinus: "-125", posPlus: '+105' }, self: { posMinus: "-125" } },
        "110": { counter: { posMinus: "-130", posPlus: '+110' }, self: { posMinus: "-130" } },
        "115": { counter: { posMinus: "-135", posPlus: '+115' }, self: { posMinus: "-135" } },
        "120": { counter: { posMinus: "-140", posPlus: '+120' }, self: { posMinus: "-140" } },
        "-120": { counter: { posMinus: "100", posMinus: '-120' }, self: { posMinus: "100" } },
        "-125": { counter: { posMinus: "105", posPlus: '-125' }, self: { posMinus: "105" } },
        "-130": { counter: { posMinus: "110", posPlus: '-130' }, self: { posMinus: "110" } },
        "-135": { counter: { posMinus: "115", posPlus: '-135' }, self: { posMinus: "115" } },
        "-140": { counter: { posMinus: "120", posPlus: '-140' }, self: { posMinus: "120" } },
    },
    strikeoutsGavela: {
        'any': { self: { strikeoutsPlus: "-130", strikeoutsMinus: "-130" } },
    },
    superGavela: {
        '-110_to_-119': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-320' }, self: { superRunLine: "+260" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+260' }, self: { superRunLine: "-320" } }
        },

        '-120_to_-129': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-300' }, self: { superRunLine: "+250" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+250' }, self: { superRunLine: "-300" } },
        },

        '-130_to_-139': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-260' }, self: { superRunLine: "+240" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+240' }, self: { superRunLine: "-260" } }
        },

        '-140_to_-149': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-260' }, self: { superRunLine: "+240" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+240' }, self: { superRunLine: "+260" } }

        },
        '-150_to_-159': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-260' }, self: { superRunLine: "+220" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+220' }, self: { superRunLine: "-260" } }

        },
        '-160_to_-169': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-230' }, self: { superRunLine: "+190" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+190' }, self: { superRunLine: "-230" } }

        },
        '-170_to_-179': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-220' }, self: { superRunLine: "+170" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+170' }, self: { superRunLine: "-220" } }

        },
        '-180_to_-189': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-210' }, self: { superRunLine: "+165" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+165' }, self: { superRunLine: "-210" } }

        },
        '-190_to_-200': {
            '-2.5': { counter: { superGavela: '+2.5', superRunLine: '-200' }, self: { superRunLine: "+160" } },
            '2.5': { counter: { superGavela: '-2.5', superRunLine: '+160' }, self: { superRunLine: "-200" } }

        },

    },
    sia: {
        '100': { counter: { sia: "+100" }, },
        '-100': { counter: { sia: "-100" }, },
        '105': { counter: { sia: "+105" }, },
        '-105': { counter: { sia: "-105" }, },
        '110': { counter: { sia: "+110" }, },
        '-110': { counter: { sia: "-110" }, },
        '115': { counter: { sia: "+115" }, },
        '-115': { counter: { sia: "-115" }, },
        '120': { counter: { sia: "+120" }, },
        '-120': { counter: { sia: "-120" }, },
        '125': { counter: { sia: "+125" }, },
        '-125': { counter: { sia: "-125" }, },
        '130': { counter: { sia: "+130" }, },
        '-130': { counter: { sia: "-130" }, },
        '135': { counter: { sia: "+135" }, },
        '-135': { counter: { sia: "-135" }, },
        '140': { counter: { sia: "+140" }, },
        '-140': { counter: { sia: "-140" }, },
        '145': { counter: { sia: "+145" }, },
        '-145': { counter: { sia: "-145" }, },
        '150': { counter: { sia: "+150" }, },
        '-150': { counter: { sia: "-150" }, },
        '155': { counter: { sia: "+155" }, },
        '-155': { counter: { sia: "-155" }, },
        '160': { counter: { sia: "+160" }, },
        '-160': { counter: { sia: "-160" }, },
        '165': { counter: { sia: "+165" }, },
        '-165': { counter: { sia: "-165" }, },
        '170': { counter: { sia: "+175" }, },
        '-170': { counter: { sia: "-175" }, },
        '180': { counter: { sia: "+180" }, },
        '-180': { counter: { sia: "-180" }, },
        '185': { counter: { sia: "+185" }, },
        '-185': { counter: { sia: "-185" }, },
        '190': { counter: { sia: "+190" }, },
        '-190': { counter: { sia: "-190" }, },
        '195': { counter: { sia: "+195" }, },
        '-195': { counter: { sia: "-195" }, },
        '200': { counter: { sia: "+200" }, },
        '-200': { counter: { sia: "-200" }, },
    },
    noa: {
        '100': { counter: { noa: "+100" }, },
        '-100': { counter: { noa: "-100" }, },
        '105': { counter: { noa: "+105" }, },
        '-105': { counter: { noa: "-105" }, },
        '110': { counter: { noa: "+110" }, },
        '-110': { counter: { noa: "-110" }, },
        '115': { counter: { noa: "+115" }, },
        '-115': { counter: { noa: "-115" }, },
        '120': { counter: { noa: "+120" }, },
        '-120': { counter: { noa: "-120" }, },
        '125': { counter: { noa: "+125" }, },
        '-125': { counter: { noa: "-125" }, },
        '130': { counter: { noa: "+130" }, },
        '-130': { counter: { noa: "-130" }, },
        '135': { counter: { noa: "+135" }, },
        '-135': { counter: { noa: "-135" }, },
        '140': { counter: { noa: "+140" }, },
        '-140': { counter: { noa: "-140" }, },
        '145': { counter: { noa: "+145" }, },
        '-145': { counter: { noa: "-145" }, },
        '150': { counter: { noa: "+150" }, },
        '-150': { counter: { noa: "-150" }, },
        '155': { counter: { noa: "+155" }, },
        '-155': { counter: { noa: "-155" }, },
        '160': { counter: { noa: "+160" }, },
        '-160': { counter: { noa: "-160" }, },
        '165': { counter: { noa: "+165" }, },
        '-165': { counter: { noa: "-165" }, },
        '170': { counter: { noa: "+175" }, },
        '-170': { counter: { noa: "-175" }, },
        '180': { counter: { noa: "+180" }, },
        '-180': { counter: { noa: "-180" }, },
        '185': { counter: { noa: "+185" }, },
        '-185': { counter: { noa: "-185" }, },
        '190': { counter: { noa: "+190" }, },
        '-190': { counter: { noa: "-190" }, },
        '195': { counter: { noa: "+195" }, },
        '-195': { counter: { noa: "-195" }, },
        '200': { counter: { noa: "+200" }, },
        '-200': { counter: { noa: "-200" }, },
    },

}

export const divideValues = (valueref, prop) => {
    if (prop === "gavela") {
        const counterValue = valueref > 0 ? (valueref * -1) : ("+" + String(valueref).replace('-', ''))
        return {
            self: {
                gavela: valueref,
                h1RlGavela: (valueref / 2),
                RunLineQ1Gavela: (valueref / 4),
                RunLineQ2Gavela: (valueref / 4),
                RunLineQ3Gavela: (valueref / 4),
                RunLineQ4Gavela: (valueref / 4),
                RLT1Gavela: (valueref / 3),
                RLT2Gavela: (valueref / 3),
                RLT3Gavela: (valueref / 3),
            },
            counter: {
                gavela: counterValue,
                h1RlGavela: (counterValue / 2),
                RunLineQ1Gavela: (counterValue / 4),
                RunLineQ2Gavela: (counterValue / 4),
                RunLineQ3Gavela: (counterValue / 4),
                RunLineQ4Gavela: (counterValue / 4),
                RLT1Gavela: (counterValue / 3),
                RLT2Gavela: (counterValue / 3),
                RLT3Gavela: (counterValue / 3),
            }
        }
    }

    if (prop === "total") {
        const valueCheck = valueref > 0 ? (valueref) : (valueref * -1)
        return {
            self: {
                total: valueCheck,
                HGavela: (valueCheck / 2),
                H2Gavela: (valueCheck / 2),
                totalQ1: (valueCheck / 4),
                totalQ2: (valueCheck / 4),
                totalQ3: (valueCheck / 4),
                totalQ4: (valueCheck / 4),
                totalT1: (valueCheck / 3),
                totalT2: (valueCheck / 3),
                totalT3: (valueCheck / 3),
            },
            counter: {
                total: valueCheck,
                HGavela: (valueCheck / 2),
                H2Gavela: (valueCheck / 2),
                totalQ1: (valueCheck / 4),
                totalQ2: (valueCheck / 4),
                totalQ3: (valueCheck / 4),
                totalQ4: (valueCheck / 4),
                totalT1: (valueCheck / 3),
                totalT2: (valueCheck / 3),
                totalT3: (valueCheck / 3),
            }
        }
    }

    return false
}