import { useState, useEffect, Fragment } from "react";
import { redApi } from "../../../../../config/Axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLeagues } from "../../../../../utils/Hooks";
// import { TableLines } from "../../../../public/layout/TableLines";
import { TableGames } from "../../../structures/theme4/TableGames";
import { matchupLeagues } from "../../../../../utils/matchUpLeagues";
import { TableMatchUps } from "../../../structures/theme4/TableMatchUps";

export default function Theme4_games({
  isLoading,
  setIsLoading,
  gamesForUser,
  filteredGames,
}) {
  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };

  // modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/changeTheme/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              window.location.reload();
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { leagues, getLeagues } = useLeagues();
  useEffect(() => {
    getLeagues();
  }, []);
  const [currentleague, setCurrentLeague] = useState({});

  useEffect(() => {
    let current = leagues?.find(league => league._id === gamesForUser?.[0]?.league._id);
    setCurrentLeague(current);
  }, [gamesForUser, leagues])

  const [lineTypeSelected, setLineTypeSelected] = useState("all");
  const handleChangeLineType = (e) => {
    let name = e.target.value;
    let value = name === "all" ? "all" : currentleague?.lineType?.find(lineType => lineType.name === name);
    setLineTypeSelected(value);
  }

  const renderTable = () => {
    if (!currentleague) return <></>;
    switch (lineTypeSelected) {
      case "all":
        return (
          currentleague.lineType?.map((lineType, indexLineType) => (
            <div key={indexLineType} className="lines-games-vertical__table mb-4">
              {
                lineType?.periods?.map((period, indexPeriod) => (
                  period.period !== "Completo / 1ra Mitad " && (
                    <Fragment key={indexPeriod}>
                      {
                        indexPeriod !== 1 ? (
                          <div className="lines-games-vertical__table__period__title">
                            <span>Periodo: {period.period}</span>
                          </div>
                        ) : null
                      }
                      {
                        matchupLeagues.includes(currentleague.name)
                        ? ( <TableMatchUps head={period.lines} games={filteredGames?.length > 0 ? filteredGames : gamesForUser} /> )
                        : ( <TableGames head={period.lines} games={filteredGames?.length > 0 ? filteredGames : gamesForUser} /> )
                      }
                    </Fragment>
                  )
                ))
              }
            </div>
          ))
        )
      default:
        return (
          <div className="lines-games-vertical__table mb-4">
            {
              lineTypeSelected?.periods?.map((period, indexPeriod) => (
                period.period !== "Completo / 1ra Mitad " && (
                  <div key={indexPeriod} className="lines-games-vertical__table__period mb-4">
                    <div className="lines-games-vertical__table__period__title">
                      <span>{period.period}</span>
                    </div>
                    {
                      matchupLeagues.includes(currentleague.name)
                      ? ( <TableMatchUps head={period.lines} games={filteredGames?.length > 0 ? filteredGames : gamesForUser} /> )
                      : ( <TableGames head={period.lines} games={filteredGames?.length > 0 ? filteredGames : gamesForUser} /> )
                    }
                  </div>
                )
              ))
            }
          </div>
        )
    }
  }
  return (
    <div className="Tables_right_container">
      {isLoading ? (
        <div
          className="spinner-border text-danger z-index-999 position-absolute top-50 start-50"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className="table-responsive">
          { renderTable() }
        </div>
      )}
    </div>
  );
}
