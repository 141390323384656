import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import HyLModalCrear from "./horarioylineasmodal/HyLModalCrear";
import { Modal, Button } from "antd";
import LoadingScreen from "../../layout/LoadingScreen";
import moment from "moment-timezone";
import { socket } from "../../../socket";
import { HyLClock } from "../../ui/HyLClock";
import { HyRContext } from "../../../HyLUtils/context/HyLContext";
import { PERIODOS, TIPO_DE_LINEA } from "../../../HyLUtils/constants";
import { useHyL } from "../../../HyLUtils/hooks/useHyL";
import { TableStatistics } from "../../../HyLUtils/components/TableStatistics";
import { replaceSpaces, replaceSpacesAndApostrophe } from "../../../utils/stringParsers";
import HyLLinesTable from "../../../HyLUtils/components/HyLLinesTable";
import ModalSuperLiniero from "./horarioylineasmodal/ModalSuperLiniero";
import { ReportsProvider } from "./reportes/context/ReportesContext";
import ScoreBox from "./reportes/ScoreBox";
import './Monitor.css'
import { redApi } from "../../../config/Axios";
import { AuthContext } from "../../../auth";

const ModalLines = ({ visible, setVisible, gameSelected, submitlinesByPeriods, allLeagues, leagueSelected, setLeagueSelected, setLinesByPeriods, linesByPeriods }) => {
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setVisible(false);
      setLoading(false);
    }, 3000);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const [tipodelinea, setTipoDeLinea] = useState(TIPO_DE_LINEA)
  const [periodos, setPeriodos] = useState(PERIODOS);

  const handleCheckTipoDeLinea = (e) => {
    const { name, checked } = e.target;
    const newTipoDeLinea = tipodelinea.map((item) => {
      if (item.label === name) {
        item.checked = checked;
      }
      return item;
    });
    setTipoDeLinea(newTipoDeLinea);
  }

  const handleCheckPeriodos = (e) => {
    const { name, checked } = e.target;
    const newPeriodos = periodos.map((item) => {
      if (item.label === name) {
        item.checked = checked;
      }
      return item;
    });
    setPeriodos(newPeriodos);
  }

  useEffect(() => {

    setLinesByPeriods({
      ...linesByPeriods,
      typeLines: tipodelinea.filter((item) => item.checked).map((item) => item.value),
      periods: periodos.filter((item) => item.checked).map((item) => item.label.toLowerCase().split(" ").join("")),
    });
  }, [tipodelinea, periodos]);

  const handleLeagueSelected = (e) => {
    const value = e.target.value;
    const league = allLeagues?.find((item) => item._id === value);
    setLeagueSelected(league);
  }

  return (
    <>
      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <Button onClick={handleCancel} className="button_cerrar">
            Cerrar
          </Button>
        }
        className="modal_lines_container"
        width="fit-content"
      >
        <div className="modal_lines">
          <div className="modal_lines_header">
            <div className="modal_lines_header_radios">
              <div className="modal_lines_header_radios_item">
                <input
                  type="radio"
                  id="radioLeague"
                  // name="radioLeague"
                  name="radioModalLineas"
                  // name="radioLeague" 
                  defaultChecked={!gameSelected}
                />
                <label htmlFor="radioLeague">Liga</label>
              </div>
              <div className="modal_lines_header_radios_item">
                <input
                  type="radio"
                  id="radioJuego"
                  name="radioModalLineas"
                  // name="radioJuego" 
                  defaultChecked={gameSelected}
                />
                <label htmlFor="radioJuego">Juego</label>
              </div>
            </div>
            <div className="modal_lines_header_select">
              <select
                className="select_item"
                name="league"
                onChange={handleLeagueSelected}
                value={leagueSelected?._id}
                disabled={gameSelected}
              >
                {
                  allLeagues?.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                    // selected={item._id === leagueSelected?._id}
                    >{item.name}</option>
                  ))
                }
              </select>
              <input type="text" className="input_item" disabled value={gameSelected !== null ? `${gameSelected?.localTeam?.name} vs ${gameSelected?.visitingTeam?.name}` : ''} />
            </div>
          </div>
          <div className="modal_lines_body">
            <div className="modal_lines_body_tipo_de_linea">
              <div className="header_action">
                <span>
                  Tipo de linea
                </span>
              </div>
              <div className="modal_lines_body_tipo_de_linea_checkboxes">
                {tipodelinea?.map((item, index) => (
                  <div className="modal_lines_body_tipo_de_linea_checkboxes_item" key={item.label}>
                    <input type="checkbox" id={item.label} name={item.label} checked={item.checked} onChange={handleCheckTipoDeLinea} />
                    <label htmlFor={item.label}>{item.label}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal_lines_body_periodos">
              <div className="header_action">
                <span>
                  Periodos
                </span>
              </div>
              <div className="modal_lines_body_periodos_checkboxes">
                {periodos?.map((item, index) => (
                  <div className="modal_lines_body_periodos_checkboxes_item" key={item.label}>
                    <input type="checkbox" id={item.label} name={item.label} checked={item.checked} onChange={handleCheckPeriodos} />
                    <label htmlFor={item.label}>{item.label}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="footer_buttons">
          <div className="header_action">
            <span>
              Accion
            </span>
          </div>
          {/* <select defaultValue="automatico" className="select_item" onChange={(e) => submitlinesByPeriods(e.target.value)}>
            <option value="automatico">Automatico</option>
            <option value="incompleto">Incompleto</option>
          </select> */}
          <Button className="button_item" value="Automatico" onClick={() => submitlinesByPeriods("AUTOMATICO")}>
            Automatico
          </Button>
          <Button className="button_item" value="MANUAL" onClick={() => submitlinesByPeriods("MANUAL")}>
            Manual
          </Button>
          <Button className="button_item" value="ELIMINAR" onClick={() => submitlinesByPeriods("ELIMINAR")}>
            Eliminar
          </Button>
          <Button className="button_item" value="AUTORIZAR" onClick={() => submitlinesByPeriods("AUTORIZAR")}>
            Autorizar
          </Button>
          <Button className="button_item" value="DESAUTORIZAR" onClick={() => submitlinesByPeriods("DESAUTORIZAR")}>
            Desautorizar
          </Button>
        </div>
      </Modal >
    </>
  );
}

export const HyLright = () => {
  const { userLevel } = useContext(AuthContext);

  const [mainBot, setMainBot] = useState([]);
  const [showScoresManual, setShowScoresManual] = useState(false);

  const {
    leaguesWithGames,
    ligaName,
    table,
    ligaActiva,
  } = useContext(HyRContext);

  const { methods, states, setStates } = useHyL();
  const {
    // handleChangeScores, 
    // handleUpdateGame, 
    approveGames,
    approveGamesLeague,
    getLeague,
    getTeams,
    handleAuthLine,
    handleAutomatic,
    handleCancelModal,
    handleChange,
    handlechangeCode,
    handleChangeLeague,
    autorizeLineChange,
    handleChangeLeagueLines,
    handleClearChanges,
    handlePeriodSelected,
    handleReCalcScores,
    handleRecalTickets,
    handleShowModalSetScores,
    handleStatusGame,
    handleTypeLineSelected,
    handleUpdateScores,
    handleVisibleForGames,
    handleVisibleForLeagues,
    loadGames,
    putAuthLine,
    putData,
    putUnauthLine,
    selectLineId,
    submitlinesByPeriods,
    syncLines,
    syncScores,
    tableStatus,
    verifyOnChangeLines,
    sendLineChanges,
    openSuperLiniero,
    closeSuperLiniero,
    getTeamsLeague,
    getData,
    handleDate,
    handleTypeLineHistorial,
    handleSearchUser
  } = methods;
  const {
    allLeagues,
    automatic,
    date,
    estatus,
    fetchLoading,
    gameSelected,
    indexGame,
    gamesRender,
    gamesTableLoading,
    gameToUpdate,
    historialRender,
    isPonche,
    league,
    leagueConfig,
    leagueSelected,
    lineIdSelected,
    linesByPeriods,
    lineToEdit,
    loading,
    loadingMessage,
    modalUpdateGame,
    periods,
    scoreStructure,
    selectedValue,
    showModalCreate,
    tableData,
    teamsDB,
    typeLineSelected,
    userCodesTeams,
    visible,
    linesChanged,
    linesChangedHashMap,
    visibleSuperLiniero,
    dataTimeBot,
    dataBot,
    historialScores,
    dateHistorial,
    typeLineHistorial,
    searchUser,
    sectedNameLeague
  } = states;
  const {
    setDate,
    setEstatus,
    setGamesRender,
    setLeagueSelected,
    setLinesByPeriods,
    setLineToEdit,
    setPeriods,
    setSelectedValue,
    setNameBot,
    setShowModalCreate,
    setTableData,
    setTypeLineSelected,
    setUserCodesTeams,
    setVisible,
    setLeague,
    setDataBot
  } = setStates;



  //* EFFECTS
  useEffect(() => {
    getTeamsLeague()
  }, [gamesRender]);
  // useEffect(() => {
  //   console.log("useEffect league 2", league)
  // }, [league]);
  useEffect(() => {
    getTeams();
    if (league) {
      setTypeLineSelected(league.lineType[0])
      setPeriods(league.lineType[0]?.periods)
      setTableData({ head: league?.lineType[0]?.periods[0]?.lines })
    }
    setLeagueSelected(league);
  }, [league])

  useEffect(() => {
    if (leagueConfig) {
      let nameInitialBot;
      let durationInitialBot;
      let timeInitialBot;
      dataTimeBot.map((item) => {
        if (item.name == leagueConfig.infoSource.name) {
          nameInitialBot = item.name;
          durationInitialBot = item.timeAmmount;
          timeInitialBot = item.timeStep
        }
      })
      setMainBot(leagueConfig.infoSource.name.toUpperCase())
      setDataBot({ name: nameInitialBot?.toUpperCase(), timeAmmount: durationInitialBot, timeStep: timeInitialBot })
      if (leagueConfig.sportBook) {
        setSelectedValue(leagueConfig.infoSource.name + "/" + leagueConfig.sportBook.name)
        setMainBot(leagueConfig.sportBook.name.toUpperCase())
        setDataBot({ name: leagueConfig.sportBook.name.toUpperCase(), timeAmmount: 1, timeStep: "Minutos", code: leagueConfig.sportBook.code })
      }
      else {
        setSelectedValue(leagueConfig.infoSource.name);
        // setNameBot(leagueConfig.infoSource.name);
      }
    }
  }, [leagueConfig, dataTimeBot]);

  useEffect(() => getLeague(), [ligaName]);



  useEffect(() => {
    loadGames();
  }, [league, table, ligaActiva, estatus, date]);
  useEffect(() => {
    let url = window.location.href;
    url = url.split("/");
    url = url[url.length - 1]; // MLB?table=lineas but only MLB
    if (gamesRender[0]?.league?.name !== url.split("?")[0].replaceAll("%20", " ")) {
      if (gamesRender[0]?.league?.name) {

      }
    }

    if (!showScoresManual) {
      const changeLinesUpdateFn = (data) => {
        console.log("data de socketsssss", data)
        const updatedGames = data.filter(game => {
          return gamesRender.some(gameRender => gameRender._id.toString() === game.id.toString())
        });
        if (updatedGames.length > 0) {
          setGamesRender(prev => {
            const gamesModified = prev.map((game) => {
              const findInUpdated = updatedGames.find((gameUpdated) => gameUpdated.id?.toString() === game._id.toString());

              if (findInUpdated && (findInUpdated.sportBook === dataBot.code?.toString())) {
                return {
                  ...game,
                  localLine: findInUpdated.localLine,
                  visitingLine: findInUpdated.visitingLine,
                }
              }
              return game
            })
            return gamesModified;
          });
        }

      }
      const deleteGame = (data) => {
        const updatedGames = data.gameIds.filter(id => {
          return gamesRender.some(gameRender => gameRender._id.toString() === id.toString())
        });

        if (updatedGames.length > 0) {
          setGamesRender(prev => {
            const gamesModified = prev.filter((game) => {

              const findInUpdated = updatedGames.find((gameUpdated) => gameUpdated.toString() === game._id.toString());
              if (!findInUpdated) {
                return true
              }
              return false
            })
            return gamesModified;
          });
        }

      }
      socket.on("changeLinesUpdate", changeLinesUpdateFn);
      socket.on("gameStart", deleteGame);
      return () => socket.off("changeLinesUpdate", changeLinesUpdateFn);


    }

  }, [gamesRender, showScoresManual]);
  const navigate = useNavigate();
  useEffect(() => {
    if (leaguesWithGames?.length > 0) {
      leaguesWithGames.forEach((e, i) => {
        if (ligaName !== e.name) {
          if (i === leaguesWithGames.length - 1) {
            navigate(`/admin/horarioslineas/${leaguesWithGames[0].name}?table=lineas`)
          }
        }
      })
    }
  }, [leaguesWithGames])
  // useEffect(() => {
  //   const handleKeyPress = async (event) => {
  //     if (event.key === "Enter") {
  //       await putData()
  //       setLineToEdit({});
  //       await Swal.fire({
  //         icon: "success",
  //         title: "Cambios guardados",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //       loadGames();
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [lineToEdit]);

  useEffect(() => {
    const handleKeyPress = async (event) => {
      if (event.key === "Enter") sendLineChanges();
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [linesChanged, linesChangedHashMap, lineIdSelected]);

  useEffect(() => {
    if (localStorage.getItem("userCodesTeams")) {
      setUserCodesTeams(localStorage.getItem("userCodesTeams"));
    } else {
      localStorage.setItem("userCodesTeams", "legacy");
    }
  }, [userCodesTeams]);

  const [teamsSelected, setTeamsSelected] = useState({
    localTeamSelected: "",
    visitingTeamSelected: "",
  });

  const handleTeamsSelected = (e) => {
    const { name, value } = e.target;
    setTeamsSelected({
      ...teamsSelected,
      [name]: value,
    });
  }
  const deleteGameRed = async () => {
    const alertLoading = Swal.fire({
      icon: "success",
      title: "Eliminando Juego Manual",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timer: 1500,
    })
    try {
      const result = await redApi.delete('/games/deleteGame', {
        headers: { 'Teams-Selected': JSON.stringify(teamsSelected) }
      });
      setTeamsSelected({
        localTeamSelected: "",
        visitingTeamSelected: "",
      })
      await loadGames();
    } catch (error) {
      console.log("error", error)
      let errorMessage = error.response.data.message;

      Swal.fire({
        title: 'Error',
        text: errorMessage,
        icon: 'error'
      });
    }
    setTeamsSelected({
      localTeamSelected: "",
      visitingTeamSelected: "",
    })
  }
  const handleShowScoresManual = () => {
    setShowScoresManual(!showScoresManual);
  }

  const ScoreManual = ({ showScoresManual, handleShowScoresManual }) => {
    return (
      <Modal
        title={<div className="custom-modal-title" style={{ fontSize: '30px', color: 'gray' }}>Escores Manuales</div>}
        open={showScoresManual}
        onCancel={() => handleShowScoresManual()}
        width={980}
        footer={[
          <Button key="close" onClick={() => handleShowScoresManual()}>
            Salir
          </Button>,

        ]}
      >
        <ReportsProvider><ScoreBox /></ReportsProvider>
      </Modal>
    )
  }
  if (loading) return <LoadingScreen>{loadingMessage}</LoadingScreen>;
  const historialRenderResult =
    !searchUser && typeLineHistorial === 'all' ? historialRender :
      !searchUser && typeLineHistorial !== 'all' ? historialRender.filter((data) => data.lineType === typeLineHistorial) :
        searchUser && typeLineHistorial === 'all' ? historialRender.filter((data) => data.user.username.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase())) :
          searchUser && typeLineHistorial !== 'all' ? historialRender.filter((data) => data.user.username.toLocaleLowerCase().includes(searchUser.toLocaleLowerCase()) && data.lineType === typeLineHistorial) :
            historialRender
  return (
    <div className="right-container-r">
      {
        <Modal
          title="Actualizar juego"
          open={modalUpdateGame}
          onCancel={handleCancelModal}
          width={1280}
          footer={[
            <Button onClick={handleCancelModal}>
              Cancelar
            </Button>,
            <Button type="primary" onClick={handleUpdateScores}>
              Guardar
            </Button>,
          ]}
        >
          <div className="row">
            <div className="col-12">
              <div className="head d-flex justify-content-center align-items-center">
                <h6 className="title">
                  {`${gameToUpdate?.localTeam?.name} vs ${gameToUpdate?.visitingTeam?.name} | ${moment(gameToUpdate?.time).format('hh:mm A')}`}
                </h6>
              </div>
              <div className="body">
                <div className="row">
                  <div className="col-12">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Equipo</th>
                          {scoreStructure.map((item, index) => (
                            <th key={index} scope="col">{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex justify-content-start align-items-center">
                              <img width={35} height={35} src={gameToUpdate?.localTeam?.image} />
                              {gameToUpdate?.localTeam?.name}
                            </div>
                          </td>
                          {scoreStructure.map((item, index) => (
                            <td key={index}>
                              <input
                                type="number"
                                min="0"
                                className="form-control border-0 bg-transparent"
                                data-id={item}
                                name="scoresLocal"
                              // value={gameToUpdate.status === 'finished' ? gameToUpdate.localLine?.scores[item] : ''}
                              />
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex justify-content-start align-items-center">
                              <img width={35} height={35} src={gameToUpdate?.visitingTeam?.image} />
                              {gameToUpdate?.visitingTeam?.name}
                            </div>
                          </td>
                          {scoreStructure.map((item, index) => (
                            <td key={index}>
                              <input
                                type="number"
                                min="0"
                                className="form-control border-0 bg-transparent"
                                data-id={item}
                                name="scoresVisit"
                              // value={gameToUpdate.status === 'finished' ? gameToUpdate.visitingLine?.scores[item] : ''}
                              />
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
      <ModalLines visible={visible} setVisible={setVisible} gameSelected={gameSelected} submitlinesByPeriods={submitlinesByPeriods} allLeagues={allLeagues} leagueSelected={leagueSelected} setLeagueSelected={setLeagueSelected} setLinesByPeriods={setLinesByPeriods} linesByPeriods={linesByPeriods} />
      <ModalSuperLiniero
        isOpen={visibleSuperLiniero}
        close={closeSuperLiniero}
        head={tableData.head}
        gameSelected={gameSelected}
        indexGame={indexGame}
        isPonches={isPonche}
        botSelected={selectedValue}
        loadGames={loadGames}
      />
      <div className="w-100 d-flex flex-column" id="hyr_container">
        <div className="head d-flex justify-content-center align-items-center row w-100 m-0 px-2 gap-1 pb-1 mt-2">
          <ul className="nav_container col d-flex justify-content-center gap-4">
            <li className="box-hyl-nav">
              <ul className="px-2">
                <li>
                  <select
                    onChange={(e) => handlechangeCode(e)}
                    className="form-select"
                    value={userCodesTeams}
                  >
                    <option
                      value="Codes"
                      style={{
                        backgroundColor:
                          userCodesTeams === "Codes" ? "#141414" : "#fff",
                        color: userCodesTeams === "Codes" ? "#fff" : "#141414",
                      }}
                    >
                      CODES
                    </option>
                    <option
                      value="legacy"
                      style={{
                        backgroundColor:
                          userCodesTeams === "legacy" ? "#141414" : "#fff",
                        color: userCodesTeams === "legacy" ? "#fff" : "#141414",
                      }}
                    >
                      LEGACY
                    </option>
                    <option
                      value="rd"
                      style={{
                        backgroundColor:
                          userCodesTeams === "rd" ? "#141414" : "#fff",
                        color: userCodesTeams === "rd" ? "#fff" : "#141414",
                      }}
                    >
                      RD
                    </option>
                  </select>
                </li>
                <li>
                  <input
                    type="date"
                    className="form-control bg-transparent border-0"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <button className="btn search">Buscar</button>
                </li>
              </ul>
            </li>
            <li className="box-hyl-nav">
              <ul className="px-2">
                <li>
                  <label className="text" aria-current="page">
                    {
                      gamesRender?.length > 0 && gamesRender[0]?.league?.name
                    }{" "}
                    {
                      gamesRender?.length > 0 && gamesRender[0]?.league?.name === "NBA-S"
                        ? "Players"
                        : gamesRender?.length > 0 && gamesRender[0]?.league?.name === "BOX/UFC"
                          ? "Fighters"
                          : "Teams"
                    }
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="localTeamSelected"
                    onChange={(e) => handleTeamsSelected(e)}
                    value={teamsSelected.localTeamSelected || "all"}
                  >
                    <option value="all">Todos</option>
                    {
                      teamsDB.sort((a, b) => (b.manual ? 1 : 0) - (a.manual ? 1 : 0))
                        .map((team, index) => (
                          <option key={index} value={team._id}>
                            {team.manual ? `${team.name}(M)` : team.name}
                          </option>
                        ))}
                  </select>
                </li>
                <li>
                  <span className="versus_text">
                    Vs:{" "}
                  </span>
                </li>
                <li>
                  <label className="text" aria-current="page">
                    {
                      gamesRender?.length > 0 && gamesRender[0]?.league?.name
                    }{" "}
                    {
                      gamesRender?.length > 0 && gamesRender[0]?.league?.name === "NBA-S"
                        ? "Players"
                        : gamesRender?.length > 0 && gamesRender[0]?.league?.name === "BOX/UFC"
                          ? "Fighters"
                          : "Teams"
                    }
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="visitingTeamSelected"
                    onChange={(e) => handleTeamsSelected(e)}
                    value={teamsSelected.visitingTeamSelected || "all"}
                  >
                    <option value="all">Todos</option>
                    {
                      teamsDB.sort((a, b) => (b.manual ? 1 : 0) - (a.manual ? 1 : 0))
                        .map((team, index) => (
                          <option key={index} value={team._id}>
                            {team.manual ? `${team.name}(M)` : team.name}
                          </option>
                        ))}
                  </select>
                </li>
                <li>
                  <button
                    className="btn crear"
                    onClick={() => setShowModalCreate(true)}
                  >
                    Crear
                  </button>
                  <HyLModalCrear
                    show={showModalCreate}
                    userCodesTeams={userCodesTeams}
                    setShow={setShowModalCreate}
                    equipos={teamsDB}
                    setGamesRender={setGamesRender}
                    loadGames={loadGames}
                    leagueSelected={leagueSelected}
                    localTeamSelected={teamsSelected.localTeamSelected}
                    visitingTeamSelected={teamsSelected.visitingTeamSelected}
                    setTeamsSelected={setTeamsSelected}
                  />
                </li>
                <li>
                  <button className="btn borrar" onClick={() => deleteGameRed()} >Borrar</button>
                </li>
              </ul>
            </li>
          </ul>

        </div>
        <div className="head d-flex justify-content-center align-items-center row w-100 m-0 px-2 gap-1 pb-1">
          <ul className="nav_container col d-flex justify-content-center gap-4">
            <li className="box-hyl-nav">
              <ul className="px-2">
                <li className="nav-item">
                  <button className="button" onClick={handleAutomatic} style={automatic ? {
                    backgroundColor: "#dc3545",
                    color: "white",
                    fontWeight: "bold"
                  } : {
                    backgroundColor: "white",
                    color: "#dc3545",
                    fontWeight: "normal"
                  }}>
                    Automático
                  </button>
                </li>
                <li onClick={syncScores}>
                  <button className="btn borrar d-flex justify-content-around align-items-center">
                    Scores <i className="fa-solid fa-rotate"></i>
                  </button>
                </li>
                <li onClick={syncLines}>
                  <button className={`btn borrar d-flex justify-content-around align-items-center ${leaguesWithGames.length < 1 ? 'active' : ''}`} id="loadLines">
                    Lineas <i className="fa-solid fa-rotate"></i>
                  </button>
                </li>
              </ul>
            </li>

            <li className="box-hyl-nav">
              <ul className="px-2">
                <li className="nav-item">
                  <a className="buttonbordered" aria-current="page" href="#" onClick={table === "lineas" ? handleVisibleForLeagues : undefined}>
                    Cambiar status
                  </a>
                </li>
                {(userLevel && userLevel == 1) && <li className="nav-item">
                  <a className="buttonbordered" aria-current="page" href="#" onClick={table === "lineas" ? handleClearChanges : undefined}>
                    Limpiar cambios
                  </a>
                </li>}
              </ul>
            </li>
            <li className="box-hyl-nav">
              <ul className="px-2">
                <li>
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="#"
                    onClick={approveGamesLeague}
                  >
                    Autorizar Liga
                  </a>
                </li>
                <li>
                  <a
                    className="nav-link"
                    aria-current="page"
                    href="#"
                    onClick={approveGames}
                  >
                    Autorizar Todo
                  </a>
                </li>
              </ul>
            </li>
            <li className="box-hyl-nav p-0 pr-1">
              <ul className="px-2">
                <li className="gap-2">
                  <HyLClock />
                  <label className="league_name">{gamesRender[0]?.league?.name}</label>
                </li>
              </ul>
            </li>
            <li className="box-hyl-nav">
              <ul className="px-2">
                <label className="time_bot">{dataBot.name} se actualiza cada {dataBot.timeAmmount} {dataBot.timeStep}</label>
              </ul>
            </li>
          </ul>
        </div>
        <div className="body p-0 m-0 mb-1 row d-flex justify-content-center gap-4 px-2 px-lg-4">
          <ul className="nav_container col d-flex justify-content-center">
            <li className="box-hyl-nav">
              <ul className="px-2">
                <li className="nav-item">
                  <label htmlFor="inputGroupSelect02">
                    League:
                  </label>
                  <select
                    className="form-select"
                    aria-label="Select for change League"
                    onChange={handleChangeLeague}
                    value={replaceSpacesAndApostrophe(ligaName)}
                    id="inputGroupSelect02"
                  >
                    {
                      leaguesWithGames?.map((item, index) => (
                        <option
                          key={index}
                          value={replaceSpacesAndApostrophe(item.name)}
                        >{replaceSpaces(item.name)}</option>
                      )
                      )
                    }
                  </select>
                </li>
                <li className="nav-item w-select">
                  <label htmlFor="inputGroupSelect01">
                    Bot:
                  </label>
                  <select
                    value={selectedValue}
                    className="form-select"
                    onChange={handleChangeLeagueLines}
                    id="inputGroupSelect01"
                  >
                    {
                      league?.bots?.map((item, index) => {
                        if (item.sportBooks?.length == 0) {
                          return (
                            <option
                              key={index}
                              value={item.name}
                            >
                              {item?.name?.toUpperCase()}
                            </option>
                          )
                        } else {
                          return item.sportBooks?.map((sportBook, index2) => (
                            <option
                              key={Number(String(index) + String(index2))}
                              value={item.name + '/' + sportBook.name}
                            >
                              {sportBook?.name?.toUpperCase()}
                            </option>
                          )
                          )
                        }
                      })
                    }
                  </select>
                  <button className="button" onClick={() => autorizeLineChange(true)} style={{
                    backgroundColor: "#dc3545",
                    color: "white",
                    fontWeight: "bold"
                  }}>
                    Autoriza Cambio de Bot
                  </button>
                </li>
              </ul>
            </li>

            <li className="box-hyl-nav">
              <ul className="px-2">
                <li className="nav-item">
                  <Link
                    to={`/admin/horarioslineas/${ligaName}?table=lineas`}
                    className={`nav-link ${table === "lineas" ? "active" : ""}`}
                    aria-current="page"
                    href="#"
                  >
                    Lineas
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/admin/horarioslineas/${ligaName}?table=juegos`}
                    className={`nav-link ${table === "juegos" ? "active" : ""}`}
                    aria-current="page"
                    href="#"
                  >
                    Juegos
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/admin/horarioslineas/${ligaName}?table=estadisticas`}
                    className={`nav-link ${table === "estadisticas" ? "active" : ""
                      }`}
                    aria-current="page"
                    href="#"
                  >
                    Estadisticas
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/admin/horarioslineas/${ligaName}?table=historial`}
                    className={`nav-link ${table === "historial" ? "active" : ""
                      }`}
                    aria-current="page"
                    href="#"
                  >
                    Historial Lineas
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/admin/horarioslineas/${ligaName}?table=scores`}
                    className={`nav-link ${table === "scores" ? "active" : ""
                      }`}
                    aria-current="page"
                    href="#"
                  >
                    Historial Scores
                  </Link>
                </li>
                <li><button className="btn borrar d-flex justify-content-around align-items-center" onClick={() => handleShowScoresManual()}>Scores Manuales</button></li>
                <li><label className="default_line">Default Line: {mainBot ? mainBot : ''}</label></li>

              </ul>
            </li>

          </ul>
        </div>
        <ScoreManual showScoresManual={showScoresManual}
          handleShowScoresManual={handleShowScoresManual} />
        {table === "lineas" && (
          <HyLLinesTable
            league={league}
            periods={periods}
            selectedValue={selectedValue}
            gamesRender={gamesRender}
            gamesTableLoading={gamesTableLoading}
            handleStatusGame={handleStatusGame}
            handleVisibleForGames={handleVisibleForGames}
            handleVisibleForLeagues={handleVisibleForLeagues}
            handlePeriodSelected={handlePeriodSelected}
            tableData={tableData}
            isPonche={isPonche}
            handleChange={handleChange}
            handleAuthLine={handleAuthLine}
            lineIdSelected={lineIdSelected}
            selectLineId={selectLineId}
            verifyOnChangeLines={verifyOnChangeLines}
            linesChanged={linesChanged}
            linesChangedHashMap={linesChangedHashMap}
            setVisible={setVisible}
            setEstatus={setEstatus}
            estatus={estatus}
            putAuthLine={putAuthLine}
            putUnauthLine={putUnauthLine}
            handleTypeLineSelected={handleTypeLineSelected}
            openSuperLiniero={openSuperLiniero}
            loadGames={loadGames}
          />
        )}

        {table === "juegos" && (
          <>
            <div className="row mb-2">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <ul className="nav_container">
                    <li className="nav-item">
                      <button className="button">Aplicar</button>
                    </li>
                    <li className="nav-item">
                      <button className="button" onClick={handleReCalcScores}>Recalcular Score</button>
                    </li>
                    <li className="nav-item">
                      <button className="button" onClick={handleRecalTickets}>Recalcular Tickets</button>
                    </li>
                    <li className="nav-item">
                      {/* <button className="btn btn-secondary">Cambiar orden</button> */}
                    </li>
                  </ul>
                  <ul className="nav_container">
                    <li className="nav-item d-flex gap-2">
                      <label
                        className="form-check-label"
                        htmlFor="gameStatus"
                      >
                        Status
                      </label>
                      <select value={estatus} onChange={(e) => { setEstatus(e.target.value) }} className="form-select" id="gameStatus">
                        <option value="all">Todos</option>
                        <option value="approved">Pendiente</option>
                        <option value="playing">En Progreso</option>
                        <option value="finished">Finalizado</option>
                        <option value="canceled">Cancelado</option>
                      </select>
                    </li>
                    <li className="nav-item d-flex gap-2 w-25">
                      <input type="checkbox" defaultChecked />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Auto Refrescar
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="body">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="thead" scope="col">
                          Fecha
                        </th>
                        <th className="thead" scope="col">
                          Hora
                        </th>
                        <th className="thead" scope="col">
                          Equipos
                        </th>
                        <th className="thead" scope="col">
                          Half
                        </th>
                        <th className="thead" scope="col">
                          Full
                        </th>
                        <th className="thead" scope="col">
                          Status
                        </th>
                        <th className="thead" scope="col">
                          Ultimo periodo
                        </th>
                        <th className="thead" scope="col">
                          Schedule
                        </th>
                        <th className="thead" scope="col">
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetchLoading
                        ? (
                          <tr>
                            <td colSpan={9}>Cargando...</td>
                          </tr>
                        )
                        : gamesRender &&
                        gamesRender.map((game, index) => (
                          <tr key={index} style={game.status != "approved" ? { cursor: "pointer" } : null} onClick={game.status != "approved" ? () => handleShowModalSetScores(game) : undefined}>
                            <td className="tdJuegos">
                              {moment(game.createdAt).format("MM/DD/YYYY")}
                            </td>
                            <td className="tdJuegos">
                              {moment(game.time)
                                .tz("America/New_York")
                                .format("hh:mm A")}
                            </td>
                            <td className="tdJuegos">
                              {game.localTeam.name} vs {game.visitingTeam.name}
                            </td>
                            <td className="tdJuegos">
                              {game.localLine.scores || game.visitingLine.scores
                                ? `${game.localLine.scores.half} - ${game.visitingLine.scores.half}`
                                : game.status === 'playing' ? "~" : ""}
                            </td>
                            <td className="tdJuegos">
                              {game.localLine.scores || game.visitingLine.scores
                                ? `${game.localLine.scores.total} - ${game.visitingLine.scores.total}`
                                : game.status === 'playing' ? "~" : ""}
                            </td>
                            <td className="tdJuegos">{tableStatus(game.status)}</td>
                            <td className="tdJuegos text-center">
                              {game?.localLine?.scores?.lastPeriod}
                            </td>
                            <td className="tdJuegos">
                              {" "}
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                              />{" "}
                            </td>
                            <td className="tdJuegos">
                              {" "}
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                              />{" "}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        {table === "historial" && (
          <>
            <div className="body row m-0 mb-2 gap-2 px-2 px-lg-4">
              <ul className="nav_container col d-flex justify-content-between">
                <li className="">
                  <label>Fecha</label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleFormControlInput1"
                    // defaultValue={moment().format("YYYY-MM-DD")}
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </li>
                <li className="">
                  <label>Tipo de linea</label>
                  <select
                    // defaultValue="all"
                    className="form-select"
                    aria-label="Default select example"
                    value={typeLineHistorial}
                    onChange={(e) => handleTypeLineHistorial(e)}
                  >

                    <option value="all">Todos</option>
                    <option value="moneyLine">moneyLine</option>
                    <option value="gavela">gavela</option>
                    <option value="total">total</option>
                    <option value="price">price</option>
                    <option value="posPlus">posPlus</option>
                    <option value="posMinus">posMinus</option>
                    <option value="singlePlus">singlePlus</option>
                    <option value="singleMinus">singleMinus</option>
                    <option value="single">single</option>
                    <option value="h1">h1</option>

                    {/* <option value="all">Todos</option>
                    <option value="moneyLine">ML</option>
                    <option value="RL">RL</option>
                    <option value="TL">TL</option>
                    <option value="SRL">SRL</option>
                    <option value="SiAnotaran">Si anotaran</option>
                    <option value="NoAnotaran">No anotaran</option>
                    <option value="PrimeraCarrera">Primera carrera</option>
                    <option value="Ponche">Ponche</option>
                    <option value="Solo">Solo</option>
                    <option value="Jugadores">Jugadores</option>
                    <option value="GSML">GS ML</option>
                    <option value="GSTL">GS TL</option>
                    <option value="Tercios">Tercios</option>
                    <option value="Serie">Serie</option>
                    <option value="R/LA">R/L A</option>
                    <option value="Tercios TL">Tercios TL</option> */}
                  </select>
                </li>
              </ul>
              <ul className="nav_container col d-flex justify-content-between">
                <li className="">
                  <label
                    className="form-check-label"
                    htmlFor="defaultCheck1"
                  >
                    Usuario
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    value={searchUser}
                    onChange={(e) => handleSearchUser(e)}
                  />
                </li>
                <li className="">
                  <button className="button" style={{ width: '90px', backgroundColor: '#dc3545', color: 'white', border: 'solid 1px red', borderRadius: '5px', marginTop: '13px' }}>Refrescar</button>
                </li>
                <li className="">
                  <label
                    className="form-check-label"
                    htmlFor="defaultCheck1"
                  >
                    Periodo
                  </label>
                  <select
                    // defaultValue="all"
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="all">Todos</option>
                    <option value="juegoCompleto">Juego completo</option>
                    <option value="1raMitad">1ra Mitad</option>
                    <option value="2daMitad">2da Mitad</option>
                    <option value="1erPeriodo">1er Periodo</option>
                    <option value="2doPeriodo">2do Periodo</option>
                    <option value="3erPeriodo">3er Periodo</option>
                    <option value="1erQuarter">1er Quarter</option>
                    <option value="2doQuarter">2do Quarter</option>
                    <option value="3erQuarter">3er Quarter</option>
                    <option value="4toQuarter">4to Quarter</option>
                  </select>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="thead" scope="col">
                          Hora
                        </th>
                        <th className="thead" scope="col">
                          Tipo de linea
                        </th>
                        {/* <th scope="col">Periodo</th> */}
                        <th className="thead" scope="col">
                          Liga
                        </th>
                        <th className="thead" scope="col">
                          Juego
                        </th>
                        <th className="thead" scope="col">
                          Anterior
                        </th>
                        <th className="thead" scope="col">
                          Actual
                        </th>
                        <th className="thead" scope="col">
                          Usuario
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {historialRenderResult &&
                        historialRenderResult.map((log, index) => (
                          <tr key={index}>
                            <td className="tdJuegos">
                              {moment(log.createdAt)
                                .tz("America/New_York")
                                .format("MM/DD/YYYY hh:mm A")}
                            </td>
                            <td
                              className="tdJuegos"
                              style={{ textTransform: "capitalize" }}
                            >
                              {log.lineType}
                            </td>
                            {/* <td className='tdJuegos'></td> */}
                            <td className="tdJuegos">{log.league.name}</td>
                            <td className="tdJuegos">
                              {
                                league?.name === "NBA-S" ? (
                                  <>

                                  </>
                                ) : (
                                  <>
                                    {log?.game?.localTeam?.name} -{" "}
                                    {log?.game?.visitingTeam?.name}
                                  </>
                                )}
                            </td>
                            <td className="tdJuegos">{log.prevValue}</td>
                            <td className="tdJuegos">{log.newValue}</td>
                            <td className="tdJuegos">{log.user.username}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        {table === "scores" && (
          <>
            <div className="body row m-0 mb-2 gap-2 px-2 px-lg-4">
              <ul className="nav_container col d-flex justify-content-between">
                <li className="">
                  <label>Fecha</label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleFormControlInput1"
                    // defaultValue={moment().format("YYYY-MM-DD")}
                    value={dateHistorial}
                    onChange={(e) => handleDate(e)}
                  />
                </li>
                {/* <li className="">
                  <label>Tipo de linea</label>
                  <select
                    defaultValue="all"
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="all">Todos</option>
                    <option value="ML">ML</option>
                    <option value="RL">RL</option>
                    <option value="TL">TL</option>
                    <option value="SRL">SRL</option>
                    <option value="SiAnotaran">Si anotaran</option>
                    <option value="NoAnotaran">No anotaran</option>
                    <option value="PrimeraCarrera">Primera carrera</option>
                    <option value="Ponche">Ponche</option>
                    <option value="Solo">Solo</option>
                    <option value="Jugadores">Jugadores</option>
                    <option value="GSML">GS ML</option>
                    <option value="GSTL">GS TL</option>
                    <option value="Tercios">Tercios</option>
                    <option value="Serie">Serie</option>
                    <option value="R/LA">R/L A</option>
                    <option value="Tercios TL">Tercios TL</option>
                  </select>
                </li> */}
                <li className="">
                  <button className="button" style={{ width: '90px', backgroundColor: '#dc3545', color: 'white', border: 'solid 1px red', borderRadius: '5px', marginTop: '13px' }}>Refrescar</button>
                </li>
              </ul>
              {/* <ul className="nav_container col d-flex justify-content-between">
                <li className="">
                  <label
                    className="form-check-label"
                    htmlFor="defaultCheck1"
                  >
                    Usuario
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                  />
                </li>
                <li className="">
                  <button className="button"  style={{width:'90px', backgroundColor:'#dc3545', color:'white', border:'solid 1px red', borderRadius:'5px', marginTop:'13px'}}>Refrescar</button>
                </li>
                <li className="">
                  <label
                    className="form-check-label"
                    htmlFor="defaultCheck1"
                  >
                    Periodo
                  </label>
                  <select
                    defaultValue="all"
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="all">Todos</option>
                    <option value="juegoCompleto">Juego completo</option>
                    <option value="1raMitad">1ra Mitad</option>
                    <option value="2daMitad">2da Mitad</option>
                    <option value="1erPeriodo">1er Periodo</option>
                    <option value="2doPeriodo">2do Periodo</option>
                    <option value="3erPeriodo">3er Periodo</option>
                    <option value="1erQuarter">1er Quarter</option>
                    <option value="2doQuarter">2do Quarter</option>
                    <option value="3erQuarter">3er Quarter</option>
                    <option value="4toQuarter">4to Quarter</option>
                  </select>
                </li>
              </ul> */}

            </div>
            <div className="row">
              <div className="col-12">
                <div className="body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="thead" scope="col">
                          Hora
                        </th>

                        <th className="thead" scope="col">Linea</th>

                        <th className="thead" scope="col">
                          Liga
                        </th>
                        <th className="thead" scope="col">
                          Juego
                        </th>
                        <th className="thead" scope="col">
                          Anterior
                        </th>
                        <th className="thead" scope="col">
                          Actual
                        </th>
                        <th className="thead" scope="col">
                          Usuario
                        </th>
                        <th className="thead" scope="col">
                          Manual
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {historialScores.length > 0 &&
                        historialScores.map((log, index) => {
                          // const utcDate = moment.utc(log.createdAt);

                          // const formattedTime = utcDate
                          //   .tz("America/New_York")
                          //   .format("hh:mm A"); 

                          return (
                            <tr key={index}>
                              <td className="tdJuegos">
                                {moment(log.createdAt)
                                  .tz("America/New_York")
                                  .format("DD/MM/YYYY hh:mm A")}
                              </td>
                              {/* <td className="tdJuegos">
                                 {dateHistorial} {formattedTime}
                                </td> */}
                              <td
                                className="tdJuegos"
                                style={{ textTransform: "capitalize" }}
                              >
                                {log.lineType}
                              </td>
                              {/* <td className='tdJuegos'></td> */}
                              <td className="tdJuegos">{log.league.name}</td>
                              <td className="tdJuegos">
                                {
                                  league?.name === "NBA-S" ? (
                                    <>

                                    </>
                                  ) : (
                                    <>
                                      {log?.game?.localTeam?.name} -{" "}
                                      {log?.game?.visitingTeam?.name}
                                    </>
                                  )}
                              </td>
                              <td className="tdJuegos">{log.prevValue}</td>
                              <td className="tdJuegos">{log.newValue}</td>
                              <td className="tdJuegos">{log.user}</td>
                              <td className="tdJuegos">{log.manual === true ? 'Si' : 'No'}</td>
                            </tr>
                          )
                        }

                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        {table === "estadisticas" && (
          <TableStatistics league={leagueSelected} />
        )}
      </div>
    </div>
  );
};