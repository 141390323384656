import React, { useState, useEffect, useContext } from 'react'
import { Container } from 'react-bootstrap'
import Layout from '../structures/layout'
import Consorcio from './configuracion/Consorcio'
import Usuarios from './configuracion/Usuarios'
import CrtlDeCompra from './configuracion/CrtlDeCompra'
import CrtlDeVenta from './configuracion/CrtlDeVenta'
import Terminales from './configuracion/Terminales'
import Jugadores from './configuracion/Jugadores'
import Precios from './configuracion/Precios'
import Restricciones from './configuracion/Restricciones'
import './configuracion/configuracion.scss'
import { UserServices } from '../../../services/userServices'
import validPrivileges from '../../../utils/validatePriv'
import Swal from 'sweetalert2'
import { useSearchParams } from 'react-router-dom'
import { Config } from './configuracion/Config'
import { Ctrldelineas } from './configuracion/Ctrldelineas'
import { AuthContext } from '../../../auth'
let allmenu = ['consorcio', 'usuarios', 'terminales', 'restricciones', 'jugadores', 'crtldecompra', 'crtldeventa', 'precios', 'configuracion', 'ctrldelineas']

let privilegesRequired = {
    terminales: 18,
    consorcio: 30,
    usuarios: 22,
    restricciones: 14,
}
// const useConfigMenu = create((set) => ({
//   menu: [
//     {
//       name: 'Consorcio',
//       tab: 'consorcio',
//     },
//     {
//       name: 'Usuarios',
//       tab: 'usuarios',
//     },
//     {
//       name: 'Terminales',
//       tab: 'terminales',
//     },
//     {
//       name: 'Restricciones',
//       tab: 'restricciones',
//     },
//     {
//       name: 'Jugadores',
//       tab: 'jugadores',
//     },
//     {
//       name: 'Control de Compra',
//       tab: 'crtldecompra',
//     },
//     {
//       name: 'Control de Venta',
//       tab: 'crtldeventa',
//     },
//     {
//       name: 'Precios',
//       tab: 'precios',
//     },
//     {
//       name: 'Configuracion',
//       tab: 'configuracion',
//     },
//     {
//       name: 'Control de Lineas',
//       tab: 'ctrldelineas',
//     },
//   ],
//   active: 'consorcio',
//   setActive: (tab) => set((state) => ({ active: tab })),
//   setMenu: (menu) => set((state) => ({ menu })),
//   addMenu: (menu) => set((state) => ({ menu: [...state.menu, menu] })),
//   removeMenu: (tab) => set((state) => ({ menu: state.menu.filter((item) => item.tab !== tab) })),
//   removeAllMenu: () => set((state) => ({ menu: [] })),
//   setAllMenu: () => set((state) => ({ menu: allmenu })),
//   setDefaultMenu: () => set((state) => ({ menu: state.menu.filter((item) => allmenu.includes(item.tab)) })),
// }))
export const Configuracion = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { userLevel } = useContext(AuthContext);
    const [menu, setMenu] = useState([])
    useEffect(() => {
        const tab = searchParams.get('tab');
        // console.log("TABBBBB", tab)
        if (!tab) {
            setSearchParams(`tab=${table}`)

        } else {
            setTable(tab)
        }

        // if (tab == 'usuarios' || tab == 'terminales') {
        //     setTable(tab)
        // } else {
        //     setSearchParams(`tab=${table}`)
        // }
        // console.log("searchParams", searchParams.get('tab'))
    }, [searchParams])

    const getUserPrivileges = async () => {
        if (userLevel === 10) {
            allmenu = allmenu.filter(item => item !== 'crtldecompra' && item !== 'jugadores' && item !== 'crtldeventa' && item !== 'precios' && item !== 'configuracion' && item !== 'ctrldelineas')
            let userPriv = await UserServices.getUserPrivileges('Configuracion')

            let valid = validPrivileges(userPriv.values, [privilegesRequired.terminales, privilegesRequired.consorcio, privilegesRequired.usuarios, privilegesRequired.restricciones])
            if (valid !== true) {
                let missingPrivileges = valid.split(': ')[1].split(', ')
                console.log('MISSING PRIVILEGES', missingPrivileges);
                if (missingPrivileges.includes('Ver terminales')) {
                    allmenu = allmenu.filter(item => item !== 'terminales')
                }
                if (missingPrivileges.includes('Ver consorcio')) {
                    allmenu = allmenu.filter(item => item !== 'consorcio')
                }
                if (missingPrivileges.includes('Ver usuarios')) {
                    allmenu = allmenu.filter(item => item !== 'usuarios')
                }

                if (missingPrivileges.includes('Configurar restricciones')) {
                    allmenu = allmenu.filter(item => item !== 'restricciones')
                }
            }
            setMenu(allmenu)
            return
        } else if (userLevel === 2) {
            allmenu = allmenu.filter(item => item !== 'crtldecompra' && item !== 'restricciones' && item !== 'jugadores' && item !== 'crtldeventa' && item !== 'precios' && item !== 'configuracion' && item !== 'ctrldelineas')
            setMenu(allmenu)
            return
        } else if (userLevel !== 4) {
            setMenu(allmenu)
            return
        }
    }

    useEffect(() => {
        if (menu.length !== 0 && userLevel == 10) {
            console.log("FIRSTTTTTT", menu[0])
            setSearchParams('tab=consorcio')
            setTable(menu[0])
        }
    }, [menu])




    const [table, setTable] = useState('consorcio')

    const handleTable = (e) => {
        setSearchParams('tab=')
        setTable(e.target.value)
    }

    // const renderTable = () => {
    //     switch (table) {
    //         case 'consorcio':
    //             return <Consorcio />
    //         case 'usuarios':
    //             return <Usuarios />
    //         case 'crtldecompra':
    //             return <CrtlDeCompra />
    //         case 'crtldeventa':
    //             return <CrtlDeVenta />
    //         case 'terminales':
    //             return <Terminales />
    //         case 'jugadores':
    //             return <Jugadores />
    //         case 'precios':
    //             return <Precios />
    //         case 'restricciones':
    //             return <Restricciones />
    //         case 'configuracion':
    //             return <Config />
    //         case 'ctrldelineas':
    //             return <Ctrldelineas />
    //         default:
    //             return <Consorcio />
    //     }
    // }


    useEffect(() => {
        // if (userLevel !== 10) return
        getUserPrivileges()
    }, [UserServices])
    const renderMenu = () => {

        return menu.map((item, index) => {
            return (
                <li key={index} className="nav-item navbuttons_container">
                    <button className={table === item ? 'active' : ''} value={item} onClick={handleTable} >{item}</button>
                </li>
            )
        })
    }

    return (
        <Layout>
            <Container className="p-4">
                <div className="row mb-4">
                    <ul className="nav">
                        {renderMenu()}
                    </ul>
                </div>
                <div className="row">
                    {
                        menu.map((item, index) => {
                            return (
                                <div key={index} className={table === item ? 'active' : 'd-none'} >
                                    {
                                        item === 'consorcio' && <Consorcio />
                                    }
                                    {
                                        item === 'usuarios' && <Usuarios />
                                    }
                                    {
                                        item === 'crtldecompra' && <CrtlDeCompra />
                                    }
                                    {
                                        item === 'crtldeventa' && <CrtlDeVenta />
                                    }
                                    {
                                        item === 'terminales' && <Terminales />
                                    }
                                    {
                                        item === 'jugadores' && <Jugadores />
                                    }
                                    {
                                        item === 'precios' && <Precios />
                                    }
                                    {
                                        item === 'restricciones' && <Restricciones />
                                    }
                                    {
                                        item === 'configuracion' && <Config />
                                    }
                                    {
                                        item === 'ctrldelineas' && <Ctrldelineas />
                                    }
                                </div>
                            )
                        })
                    }

                </div>
            </Container>
        </Layout>
    )
}
