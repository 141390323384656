import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { redApi } from '../../../../config/Axios';
import { Modal } from 'antd';
import WebuserModal from '../../../admin/pages/reportes/WebuserModals/WebuserModal';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import ReportesModal from '../../navigation/reportes/ReportesModal';
import { Printer } from '../../../printer/Print';
import Swal from 'sweetalert2';
import { AuthContext } from '../../../../auth';

const Rightcontainer = styled.div`
  width: 100%;
  height: 100vh - 100px;
  overflow-y: hidden;
  background-color: #fff;
  border: 1px solid #00000010;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 5px 0 20px #00000008;

  .rowcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .inputstyle {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #00000020;
    min-width: 30%;
  }

  .buttonicon {
    padding: 3px 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;


    &.blue {
      background-color: #2186F9;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.black {
      background-color: #202025;
    }
    &.yellow {
      background-color: #F7BE06;
    }
    &.red {
      background-color: #F9285A;
    }
    &.green {
      background-color: #16C754;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
  }

  .tablecontainer {
    height: calc(100vh - 300px);
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #2186F9;
    }
    
    table {
      width: 100%;
      border-collapse: collapse;
      text-align:center;
      border: 1px solid #00000020;
      thead {
        background-color: #00000005;
        th {
          padding: 10px 0;
          font-size: 15px;
          font-weight: 600;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #00000010;
          td {
            padding: 10px 0;
            font-size: 15px;
          }

          &:nth-child(even) {
            background-color: #00000005;
          }
        }
      }
      tfoot {
        tr {
          td {
            padding: 10px 0;
            font-size: 15px;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .bg-blue {
    background-color: #2186F9;
    color: #fff;
    font-weight: 400;
  }
`;

const ReportTableContainer = styled.div`
table {
  width: 100%;
  border-collapse: collapse;
  text-align:center;
  border: 1px solid #00000020;
  thead {
    background-color: #00000005;
    th {
      padding: 10px 0;
      font-size: 15px;
      font-weight: 600;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #00000010;
      td {
        padding: 10px 0;
        font-size: 15px;
      }

      &:nth-child(even) {
        background-color: #00000005;
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 10px 0;
        font-size: 15px;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

  svg {
    width: 20px;
    height: 20px;
    stroke: #222;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;
const F83right = () => {
  // /tickets?initDate=2024-03-11&finalDate=2024-03-11
  

  const [tickets, setTickets] = useState([])
  const [allTickets, setAllTickets] = useState([])
  const [filters, setFilters] = useState({
    initDate: moment().format('YYYY-MM-DD'),
    finalDate: moment().format('YYYY-MM-DD'),
    ticketState: 'all', // Play, Cancelled, Winner, Loser
  })
  const [isLoaded, setIsLoaded] = useState(false)
  const getTickets = async () => {
    try {
      setIsLoaded(false)
      const { data } = await redApi.get('/tickets', {
        params: {
          initDate: filters.initDate,
          finalDate: filters.finalDate,
          ticketState: filters.ticketState
        }
      })
      setAllTickets(data)
      setTickets(data)
      console.log(data);
      setIsLoaded(true)
    } catch (error) {
      console.log(error);
      setIsLoaded(true)
    }
  }
 

  useEffect(() => {
    getTickets()
  }, [filters.initDate, filters.finalDate])

  const handleFilter = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (filters.ticketState === 'all') {
      setTickets(allTickets)
    } else {
      setTickets(allTickets.filter(ticket => ticket.ticketState === filters.ticketState))
    }
  }, [filters.ticketState])

  const [showModal, setShowModal] = useState(false)
  const [modalToShow, setModalToShow] = useState("")

  const modal = useMemo(() => {
    return showModal
  }, [showModal])

  const handleModal = (modal) => {
    setModalToShow(modal)
    setShowModal(true)
  }

  const [printTicket, setPrintTicket] = useState({
    ticketId: 0,
    print: false,
  })
  const handleRePrint = async (ticketId) => {
    setPrintTicket({
      ticketId: ticketId,
      print: true
    })
  }
  const handleCancelTicket = async (ticketId) => {
    Swal.fire({
      title: 'Estas seguro?',
      text: `Estas seguro de cancelar el ticket #${ticketId}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await redApi.put(`/tickets/${ticketId}/cancel`)
            .then(() => {
              getTickets()
              Swal.fire({
                icon: 'success',
                title: 'Ticket Cancelado',
                text: `El ticket #${ticketId} ha sido cancelado`
              })
            })

        } catch (error) {
          console.log(error);
          const res = error.response
          if (res.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.data.message
            })
            return
          }
        }
      }
    })

  }
  const renderContent = () => {
    switch (modalToShow) {
      case 'reprint':
        return (
          <div className='reprintcontainer'>
            <div className='rowcontainer'>
              <span className='title'>Reimprimir Ticket</span>
              <div>
                <input type="date" className='inputstyle' defaultValue={moment().format('YYYY-MM-DD')} onChange={handleFilter} name='initDate' />
                <input type="date" className='inputstyle' defaultValue={moment().format('YYYY-MM-DD')} onChange={handleFilter} name='finalDate' />
              </div>
            </div>
            <ReportTableContainer >
              <table>
                <thead>
                  <tr>
                    <th>#Tk</th>
                    <th>Fecha/Hora</th>
                    <th>POS</th>
                    <th>Tipo</th>
                    <th>Apostado</th>
                    <th>A Pagar</th>
                    <th>Status</th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !isLoaded ? <tr><td colSpan='6'>Cargando...</td></tr> :
                      tickets.length === 0 ? <tr><td colSpan='6'>No hay tickets</td></tr> :
                        tickets.map((ticket, index) => (
                          <tr key={index} onClick={() => handleRePrint(ticket.ticketId)}>
                            <td>#{ticket.ticketId}</td>
                            <td>{moment(ticket.createdAt).format('ddd MM, hh:mm a')}</td>
                            <td>{ticket.createdBy.username}</td>
                            <td>{ticket.ticketType}</td>
                            <td>${Number(ticket.amount).toFixed(2)}</td>
                            <td>${Number(ticket.toCollect).toFixed(2)}</td>
                            <td>{ticket.ticketState}</td>
                            <td>
                              <button onClick={() => handleRePrint(ticket.ticketId)}>
                                <svg stroke="#fff" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" buttonicon xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>
                              </button>
                            </td>
                          </tr>
                        ))
                  }
                </tbody>
              </table>
            </ReportTableContainer>
          </div>
        )
      case 'cancelticket':
        return (
          <div className='reprintcontainer'>
            <div className='rowcontainer'>
              <span className='title'>Reimprimir Ticket</span>
              <div>
                <input type="date" className='inputstyle' defaultValue={moment().format('YYYY-MM-DD')} onChange={handleFilter} name='initDate' />
                <input type="date" className='inputstyle' defaultValue={moment().format('YYYY-MM-DD')} onChange={handleFilter} name='finalDate' />
              </div>
            </div>
            <ReportTableContainer >
              <table>
                <thead>
                  <tr>
                    <th>#Tk</th>
                    <th>Fecha/Hora</th>
                    <th>POS</th>
                    <th>Tipo</th>
                    <th>Apostado</th>
                    <th>A Pagar</th>
                    <th>Status</th>
                    <th>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !isLoaded ? <tr><td colSpan='6'>Cargando...</td></tr> :
                      tickets.length === 0 ? <tr><td colSpan='6'>No hay tickets</td></tr> :
                        tickets.map((ticket, index) => (
                          <tr key={index} onClick={() => handleCancelTicket(ticket.ticketId)}>
                            <td>#{ticket.ticketId}</td>
                            <td>{moment(ticket.createdAt).format('ddd MM, hh:mm a')}</td>
                            <td>{ticket.createdBy.username}</td>
                            <td>{ticket.ticketType}</td>
                            <td>${Number(ticket.amount).toFixed(2)}</td>
                            <td>${Number(ticket.toCollect).toFixed(2)}</td>
                            <td>{ticket.ticketState}</td>
                            <td>
                              <button onClick={() => handleCancelTicket(ticket.ticketId)}>
                                <svg stroke="#fff" fill="none" stroke-width="0" viewBox="0 0 24 24" buttonicon xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-width="2" d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M5,5 L19,19"></path></svg>
                              </button>
                            </td>
                          </tr>
                        ))
                  }
                </tbody>
              </table>
            </ReportTableContainer>
          </div>
        )
      default:
        return (
          <>
          </>
        )
    }
  }

  // change theme
  const [userTheme, setUserTheme] = useState(1);
  const handleChangeTheme = (e) => {
    setUserTheme(e.target.value);
  };
  const { logout, userTheme: themeofuser, userId, userName } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal2 = () => {
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    setIsModalVisible(false);
    await redApi.get("/verify").then(async (res) => {
      if (res.data) {
        if (res.data._id) {
          await redApi
            .put(`/users/changeTheme/${res.data._id}`, { theme: userTheme })
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Theme changed",
                showConfirmButton: false,
                timer: 1500,
              });
              window.location.reload();
            });
        }
      }
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
/********************************************************************menu */
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }
  const [showReports, setShowReports] = useState(false)
  // const [tickets, setTickets] = useState([])
  const [inLoading, setInLoading] = useState(false)
  const [reportsType, setReportsType] = useState('')
  const handleReports = (show) => {
    setShowReports(!showReports)
    setReportsType(show)
  }

  const [reportsModalOpen, setReportsModalOpen] = useState(false)
  const handleReportsModal = () => {
    setReportsModalOpen(!reportsModalOpen)
  }

  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    navigate('/', {
      replace: true
    });
  }

  const [showHelp, setShowHelp] = useState(false)
  const hadnleshowHelp = () => {
    setShowHelp(!showHelp)
  }

  const habdleModalCrear = () => {
    setModalCrear(!modalCrear)
  }

  // date filters
  const handleFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    })
  }

  // get tickets if filters change
  const handleGetTickets = async () => {
    let { fromDate, toDate } = filters
    // if toDate is today, add 24 hours to get all tickets
    if (toDate === moment().format('YYYY-MM-DD')) {
      toDate = moment().add(1, 'days').format('YYYY-MM-DD')
    }

    setInLoading(true)
    await api.get(`/tickets?initDate=${fromDate}&finalDate=${toDate}`)
      .then(res => {
        let tickets = res.data.filter(ticket => {
          if (reportsType === 'reports') return ticket
          if (reportsType === 'cancelticket') return ticket.ticketState === 'Play'
          if (reportsType === 'reprint') return ticket.ticketState !== 'Cancelled'
          if (reportsType === 'salesResults') return ticket.ticketState === 'Winner' || ticket.ticketState === 'Loser'
        })
        setTickets(tickets)
        setInLoading(false)
      })
      .catch(err => {
        console.log(err)
      }
      )
  }

  let helpTexts = [
    { key: 'm', text: 'Money Line' },
    { key: 'mh', text: 'Money Line H1' },
    { key: 'mt1', text: 'Money Line P1' },
    { key: 'mt2', text: 'Money Line P2' },
    { key: 'mt3', text: 'Money Line P3' },
    { key: 'r', text: 'Run Line' },
    { key: 'h', text: 'Run Line H' },
    { key: 'q1', text: 'Run Line Q1' },
    { key: 'q2', text: 'Run Line Q2' },
    { key: 'q3', text: 'Run Line Q3' },
    { key: 'q4', text: 'Run Line Q4' },
    { key: 't1', text: 'Run Line P1' },
    { key: 't2', text: 'Run Line P2' },
    { key: 't3', text: 'Run Line P3' },
    { key: 'hr2', text: 'Run Line H2' },
    { key: '+', text: 'Over' },
    { key: 'h+', text: 'Over H' },
    { key: 'q1+', text: 'Over Q1' },
    { key: 'q2+', text: 'Over Q2' },
    { key: 'q3+', text: 'Over Q3' },
    { key: 'q4+', text: 'Over Q4' },
    { key: 't1+', text: 'Over P1' },
    { key: 't2+', text: 'Over P2' },
    { key: 't3+', text: 'Over P3' },
    { key: 'h2+', text: 'Over H2' },
    { key: '-', text: 'Under' },
    { key: 'h-', text: 'Under H' },
    { key: 'q1-', text: 'Under Q1' },
    { key: 'q2-', text: 'Under Q2' },
    { key: 'q3-', text: 'Under Q3' },
    { key: 'q4-', text: 'Under Q4' },
    { key: 't1-', text: 'Under P1' },
    { key: 't2-', text: 'Under P2' },
    { key: 't3-', text: 'Under P3' },
    { key: 'h2-', text: 'Under H2' },
    { key: 's+', text: 'Solo +' },
    { key: 'h1s+', text: 'Solo +H' },
    { key: 'q1s+', text: 'Solo +Q1' },
    { key: 'q2s+', text: 'Solo +Q2' },
    { key: 'q3s+', text: 'Solo +Q3' },
    { key: 'q4s+', text: 'Solo +Q4' },
    { key: 'h2s+', text: 'Solo +H' },
    { key: 's-', text: 'Solo -' },
    { key: 'h1s-', text: 'Solo -H' },
    { key: 'q1s-', text: 'Solo -Q1' },
    { key: 'q2s-', text: 'Solo -Q2' },
    { key: 'q3s-', text: 'Solo -Q3' },
    { key: 'q4s-', text: 'Solo -Q4' },
    { key: 'h2s-', text: 'Solo -H' },
    { key: 'p', text: 'Super Run Line' },
    { key: 'y', text: 'Yes anotaran' },
    { key: 'n', text: 'No anotaran' },
    { key: 'k+', text: 'Strikeout +' },
    { key: 'k-', text: 'Strikeout -' },
    { key: 'f', text: 'First run' },
    { key: 'c', text: 'Buying' },
    { key: 'v', text: 'Senta' },
    { key: 'c+', text: 'B Over' },
    { key: 'c-', text: 'B Under' },
    { key: 'j1', text: 'Players 1' },
    { key: 'j2', text: 'Players 2' },
    { key: 'j3', text: 'Players 3' },
    { key: 'j4', text: 'Players 4' },
    { key: 'j5', text: 'Players 5' },
    { key: 'g', text: 'GS ML' },
    { key: 'g+', text: 'GS Over' },
    { key: 'g-', text: 'GS Under' },
    { key: 'se', text: 'Serie' },
    { key: 'ar', text: 'Run Line Alternativo' },
    { key: 't', text: 'Tercio' },
    { key: 't+', text: 'Tercio Mas' },
    { key: 't-', text: 'Tercio Menos' },
    { key: 'v+', text: 'S a mas' },
    { key: 'v-', text: 'S a menos' },
    { key: 'e', text: 'Draw' },
    { key: 'hr1', text: 'Run Line H' },
    { key: 'h1+', text: 'Over H' },
    { key: 'h1-', text: 'Under H' }
  ]

  const HelpModal = () => {
    return (
      <Modal
        open={showHelp}
        onCancel={hadnleshowHelp}
        footer={null}
      >
        <div className="row">
          <div className="col">
            <h3>How to use the system</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Enter</th>
                  <th scope="col">A Juego</th>
                </tr>
              </thead>
              <tbody>
                {
                  helpTexts.map((helpText, index) => (
                    <tr key={index}>
                      <td>{helpText.key}</td>
                      <td>{helpText.text}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    )
  }

  const initialOwnedBy = userId ? userId : '';
  const [modalEditar, setModalEditar] = useState(false)
  const [userSelected, setUserSelected] = useState(null)
  const [modalCrear, setModalCrear] = useState(false)
  const [data, setData] = useState({
    // username: "",
    isGroup: "",
    // email: "",
    theme: 1,
    ownedBy: initialOwnedBy,
  });


  return (
    <Rightcontainer>
      {
        printTicket.print &&
        <Printer
          ticketId={printTicket.ticketId}
          printTicket={printTicket}
          setPrintTicket={setPrintTicket}
        />
      }
      <Modal open={modal && modalToShow !== 'reports'} onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
        {renderContent()}
      </Modal>
      <ReportesModal
        open={showModal && modalToShow === 'reports'}
        onCancel={() => setShowModal(false)}
      />
      <HelpModal />
      <WebuserModal userName={userName} data={data} setData={setData} setModalEditar={setModalEditar} modalEditar={modalEditar} usuario={userSelected} modalCrear={modalCrear} setModalCrear={setModalCrear} />
      <div className="col d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100" onClick={openMenu}>
              <i className="fas fa-bars px-1"></i> 
            </button>
      <Modal
        title="Menu"
        open={menuOpen}
        onCancel={closeMenu}
        width={ '80vw' }
        footer={null}
      >
      <div className={`row footer_container py-2 px-2 gap-2`}>
            {

              <div style={{
                width: '100%',
                height: '15%',
                background: 'linear-gradient(90deg, rgba(32, 32, 32, 1) 0%, rgba(0, 53, 80, 1) 24%, rgba(5, 153, 230, 1) 100%)',
                background: 'rgb(32, 32, 32)',
                background: 'linear-gradient(90deg, rgba(32, 32, 32, 1) 0%, rgba(0, 53, 80, 1) 24%, rgba(5, 153, 230, 1) 100%)',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                borderRadius: '5px',
              }}
              >
              </div>
              
            }
            <button className="col footer_button" onClick={() => handleReports('cancelticket')}>Cancel Ticket</button>
            <button className="col footer_button" onClick={() => handleReports('reprint')}>Re-print</button>
            <button className="col footer_button" disabled>Save</button>
            <button className="col footer_button" disabled>Texto</button>
            <select className="col footer_button" disabled>
              <option value={'Print'}>Print</option>
            </select>

            <button className="col footer_button" onClick={() => handleReports('salesResults')}>Sales Results </button>
            <button className="col footer_button"
              onClick={() => handleReportsModal()}
            >Reports</button>
            <button className="col footer_button d-flex align-items-center justify-content-center gap-1" onClick={hadnleshowHelp}>
              <QuestionCircleOutlined />
              Help
            </button>
            <button className="col footer_button d-flex align-items-center justify-content-center gap-1" onClick={habdleModalCrear}>
              <UserAddOutlined />
              Add WebUser
            </button>
            <button className="col footer_button d-flex align-items-center justify-content-center gap-1" onClick={onLogout}>
              <LogoutOutlined />
              Exit
            </button>
          </div>
      

      </Modal>
      <ReportesModal
        open={reportsModalOpen}
        onCancel={handleReportsModal}
      />      
     </div>
     <Modal
        open={showReports}
        onCancel={handleReports}
        footer={null}
        width={ '100vw' }
        className='modal_reports'
      >
           <div className="row" id='reportsmodalsimple'>
              <div className="col-12 d-flex justify-content-center flex-column align-items-center gap-4">
                {/* date filters */}
                <div className="d-flex gap-4 align-items-end justify-content-center">
                  <div className="col">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" name="fromDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                  </div>
                  <div className="col hide_mobile">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" name="toDate" onChange={handleFilters} defaultValue={moment().format('YYYY-MM-DD')} />
                  </div>
                  <div className="col">
                    <button className="btn btn-primary" onClick={handleGetTickets}>Get Tickets</button>
                  </div>
                </div>
                <table className="table-sm">
                  <thead>
                    <tr>
                      {/* <th scope="col">Consorcio</th> */}
                      <th scope="col" className='text-center'>Ticket #</th>
                      <th scope="col" className='text-center'>Fecha/Hora</th>
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>POS</th>
                      }
                      {/* <th scope="col" className='text-center'>Usuario</th> */}
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>Tipo de Ticket</th>
                      }

                      <th scope="col" className='text-center'>Apostado</th>
                      {
                        reportsType === 'salesResults' ? null : <th scope="col" className='text-center'>A pagar</th>
                      }
                      <th scope="col" className='text-center'>Status</th>
                    </tr>
                  </thead>
                  <tbody className='position-relative'>
                    {
                      inLoading ? (
                        <tr className="spinner-border text-primary position-absolute top-50 start-50" role="status">
                          <td colSpan={7} className="visually-hidden">Loading...</td>
                        </tr>
                      ) : (
                        tickets.length === 0 ? (
                          <tr>
                            <td colSpan={7} className='text-center'>No hay tickets</td>
                          </tr>
                        ) : (
                          tickets.map((ticket, index) => (

                            <tr key={index} onClick={reportsType === 'reprint' ? () => handleRePrint(ticket.ticketId) : reportsType === 'cancelticket' ? () => handleCancelTicket(ticket.ticketId) : null}>
                              {/* <td>{ticket.consortium || 'N/A'}</td> */}
                              <td className='text-center'>#{ticket.ticketId || 'N/A'}</td>
                              <td className='text-center'>{moment(ticket.createdAt).format('DD/MM/YYYY hh:mm A')}</td>
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.createdBy.username || 'N/A'}</td>
                              }

                              {/* <td className='text-center'>{ticket.user || 'N/A'}</td> */}
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>{ticket.bets.length === 1 ? 'Direct' : ticket.bets.length === 2 ? 'Pale' : ticket.bets.length === 3 ? 'Tripleta' : ticket.bets.length === 4 ? 'Cuadruple' : ticket.bets.length === 5 ? 'Quintuple' : ticket.bets.length === 6 ? 'Sextuple' : ticket.bets.length === 7 ? 'Septuple' : ticket.bets.length === 8 ? 'Octuple' : ticket.bets.length === 9 ? 'Nonuple' : ticket.bets.length === 10 ? 'Decuple' : 'N/A'}</td>
                              }
                              <td className='text-center'>${ticket.amount || 'N/A'}</td>
                              {
                                reportsType === 'salesResults' ? null : <td className='text-center'>${ticket.toCollect || 'N/A'}</td>
                              }
                              <td className={`text-center ${ticket.ticketState === 'Play' ? 'text-warning' : ticket.ticketState === 'Winner' ? 'text-success' : ticket.ticketState === 'Loser' ? 'text-danger' : 'text-secondary'}`}
                              >{ticket.ticketState || 'N/A'}</td>
                            </tr>
                          ))
                        )
                      )
                    }
                  </tbody>
                  {
                    reportsType !== 'reports' ? null : (
                      <tfoot>
                        <tr>
                          <td colSpan={4} className='text-center'>Total</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0)}</td>
                          <td className='text-center'>${tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0) - tickets.reduce((acc, ticket) => acc + ticket.amount, 0)}</td>
                        </tr>
                      </tfoot>
                    )
                  }

                </table>
              </div>
            </div>    
      </Modal>
      
      <div className="col d-flex flex-column justify-content-center align-items-center">
            <button className="right_button w-100" onClick={showModal2}>

              <i className="fas fa-adjust px-1"></i>
              change theme
            </button>
      <Modal
        title="Change Theme"
        open={isModalVisible}
        onCancel={handleCancel}
        width={"30%"}
        onOk={handleOk}
      >
        <div className="d-flex flex-column justify-content-center align-items-center py-2">
          <i className="fas fa-adjust px-1 h1"></i>
        </div>
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <select
            defaultValue={themeofuser || 0}
            className="form-select col-6"
            aria-label="Default select example"
            onChange={handleChangeTheme}
          >
            <option disabled value={0}>Choose theme</option>
            <option value="1" selected={themeofuser === 1} disabled={themeofuser === 1}>Legacy</option>
            <option value="2" selected={themeofuser === 1} disabled={themeofuser === 2}>Red</option>
            <option value="3" selected={themeofuser === 1} disabled={themeofuser === 3}>Azul</option>
            <option value="4" selected={themeofuser === 1} disabled={themeofuser === 4}>Theme 4</option>
            <option value="5" selected={themeofuser === 1} disabled={themeofuser === 4}>F83</option>
          </select>
        </div>
      </Modal>
      </div>
      <div className='rowcontainer' style={{
        borderBottom: '1px solid #00000020'
      }}>
        <div className=''>
          <span className='title'>
            Tickets Registrados
          </span>
          <br />
          <input type="text"
            placeholder='Buscar ticket'
            className='inputstyle' />
        </div>
        <div className='d-flex'>
          <button className='buttonicon blue' onClick={showModal2}>
            <svg stroke="currentColor" fill="#fff" stroke-width="0" viewBox="0 0 24 24" buttonicon xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path></svg>
          </button>
          <button className='buttonicon black' id='refreshreportstickets' onClick={() => getTickets()}>
            <svg stroke="currentColor" fill="#fff" stroke-width="0" version="1.1" viewBox="0 0 17 17" buttonicon xmlns="http://www.w3.org/2000/svg"><g></g><path d="M6 8h-6v-6h1v4.109c1.013-3.193 4.036-5.484 7.5-5.484 3.506 0 6.621 2.36 7.574 5.739l-0.963 0.271c-0.832-2.95-3.551-5.011-6.611-5.011-3.226 0.001-6.016 2.276-6.708 5.376h4.208v1zM11 9v1h4.208c-0.693 3.101-3.479 5.375-6.708 5.375-3.062 0-5.78-2.061-6.611-5.011l-0.963 0.271c0.952 3.379 4.067 5.739 7.574 5.739 3.459 0 6.475-2.28 7.5-5.482v4.108h1v-6h-6z"></path></svg>.
          </button>
          <button className='buttonicon yellow' onClick={() => handleModal('reprint')}>
            <svg stroke="#fff" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" buttonicon xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>
          </button>
          <button className='buttonicon red' onClick={() => handleModal('cancelticket')}>
            <svg stroke="#fff" fill="none" stroke-width="0" viewBox="0 0 24 24" buttonicon xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-width="2" d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M5,5 L19,19"></path></svg>
          </button>
          <button className='buttonicon green' onClick={() => handleModal('reports')}>
            <svg stroke="#fff" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"></path><path d="M9 17v-5"></path><path d="M12 17v-1"></path><path d="M15 17v-3"></path></svg>
          </button>
        </div>
      </div>
      <div className='rowcontainer'>
        <span className='title'>Reporte Ticket</span>
        <div>
          <input type="date" className='inputstyle' defaultValue={moment().format('YYYY-MM-DD')} onChange={handleFilter} name='initDate' />
          <input type="date" className='inputstyle' defaultValue={moment().format('YYYY-MM-DD')} onChange={handleFilter} name='finalDate' />
        </div>
      </div>
      <div className='rowcontainer'>
        <select className='inputstyle' onChange={handleFilter} name='ticketState'>
          <option value="all">Todos</option>
          <option value="Winner">Ganadores</option>
          <option value="Loser">Perdedores</option>
          <option value="Cancelled">Cancelados</option>
          <option value="Play">Play</option>
        </select>
        <div className='rowcontainer gap-2' >
          <button className='buttonicon black rounded text-white d-flex gap-2'>
            <svg stroke="currentColor" fill="#fff" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"></path></svg>
            Pago pendiente
          </button>
          <div className='d-flex'>
            <button className='buttonicon blue text-white d-flex gap-2'>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.25 0a8.25 8.25 0 0 0-6.18 13.72L1 22.88l1.12 1 8.05-9.12A8.251 8.251 0 1 0 15.25.01V0zm0 15a6.75 6.75 0 1 1 0-13.5 6.75 6.75 0 0 1 0 13.5z"></path></svg>
              Buscar
            </button>
            <button className='buttonicon green' style={{
              background: 'transparent',
              border: '2px dashed #2186F9'
            }}>
              <svg stroke="currentColor" fill="#2186F9" stroke-width="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z"></path></svg>
            </button>
          </div>
        </div>
      </div>
      <div className='tablecontainer'>
        <table>
          <thead>
            <tr>
              <th>#Tk</th>
              <th>Fecha</th>
              <th>$Total</th>
              <th>$Premio</th>
              <th>Pagado</th>
            </tr>
          </thead>
          <tbody >
            {
              !isLoaded ? <tr><td colSpan='6'>Cargando...</td></tr> :
                tickets.length === 0 ? <tr><td colSpan='6'>No hay tickets</td></tr> :
                  tickets.map((ticket, index) => (
                    <tr key={index}>
                      <td>#{ticket.ticketId}</td>
                      <td>{moment(ticket.createdAt).format('ddd MM, hh:mm a')}</td>
                      <td>${Number(ticket.amount).toFixed(2)}</td>
                      <td>${Number(ticket.toCollect).toFixed(2)}</td>
                      <td>{ticket.payState}</td>
                    </tr>
                  ))
            }
          </tbody>
          <tfoot >
            <tr className='bg-blue'>
              <td>
                {
                  tickets.length
                } Tickets
              </td>
              <td></td>
              <td>
                ${
                  tickets.reduce((acc, ticket) => acc + ticket.amount, 0).toFixed(2)
                }
              </td>
              <td>
                ${
                  tickets.reduce((acc, ticket) => acc + ticket.toCollect, 0).toFixed(2)
                }
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Rightcontainer>
    
  )
}

export default F83right