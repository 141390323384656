import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { redApi } from "../../../../config/Axios";
import { Modal, Button, Form, Input, Select, message } from "antd";
import { AuthContext } from "../../../../auth";
import { UserServices } from "../../../../services/userServices";

const ModalRestricciones = (props) => {
  const { userLevel } = useContext(AuthContext);

  const [leagues, setLeagues] = useState([]);

  const handleOk = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
      addRestriccion();
    } else if (props.modalCrear) {
      props.setModalCrear(false);
      addRestriccion();
    }
  };
  useEffect(() => {
    redApi
      .get("/leagues")
      .then((res) => {
        setLeagues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleCancel = () => {
    if (props.modalEditar) {
      props.setModalEditar(false);
    } else if (props.modalCrear) {
      props.setModalCrear(false);
    }
  };



  let tipodetikcet = [
    "Todos",
    "Directo",
    "Pale",
    "Tripleta",
    "Cuarteta",
    "Quinteta",
    "Sexteta",
    "Septeta",
    "Octeta",
    "Noneta",
    "Decima",
    "Decima primera",
    "Decima segunda",
    "Decima tercera",
    "Decima cuarta",
    "Decima quinta",
    "Decima sexta",
  ];
  let tipodelinea = [
    "Todos",
    "ML",
    "TL",
    "RL",
    "SRL",
    "Si anotaran",
    "No anotaran",
    "Primera carrera",
    "Ponche",
    "Solo",
    "Jugadores",
    "GS ML",
    "GS TL",
    "Tercios",
    "Serie",
    "R/L A",
    "Tercios TL",
  ];
  let periodo = [
    "Todos",
    "Juego completo",
    "Mitad",
    "Segunda mitad",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "P1",
    "P2",
    "P3",
  ];

  const [restriccion, setRestriccion] = useState({
    league: props?.RestriccionSelected?.league?._id
      ? props?.RestriccionSelected?.league?._id
      : "",
    ticketType: props?.RestriccionSelected?.ticketType
      ? props?.RestriccionSelected?.ticketType
      : [],
    lineType: props?.RestriccionSelected?.lineType
      ? props?.RestriccionSelected?.lineType
      : [],
    period: props?.RestriccionSelected?.period
      ? props?.RestriccionSelected?.period
      : [],
    type: props?.RestriccionSelected?.type
      ? props?.RestriccionSelected?.type
      : props?.resType == "Por Jugada"
        ? "PJ"
        : props?.resType == "Por Combinacion"
          ? "PC"
          : "PT",
    playType1: props?.RestriccionSelected?.playType1
      ? props?.RestriccionSelected?.playType1
      : [],
    playType2: props?.RestriccionSelected?.playType2
      ? props?.RestriccionSelected?.playType2
      : [],
  });

  const handlechange = (e) => {
    if (e.target.name === "ticketType") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            ticketType: tipodetikcet,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          ticketType: [...restriccion.ticketType, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            ticketType: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          ticketType: restriccion.ticketType.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else if (e.target.name === "lineType") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            lineType: tipodelinea,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          lineType: [...restriccion.lineType, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            lineType: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          lineType: restriccion.lineType.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else if (e.target.name === "period") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            period: periodo,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          period: [...restriccion.period, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            period: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          period: restriccion.period.filter((item) => item !== e.target.value),
        });
      }
    } else if (e.target.name === "playType1") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType1: PlaysTypes,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType1: [...restriccion.playType1, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType1: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType1: restriccion.playType1.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    } else if (e.target.name === "playType2") {
      if (e.target.checked) {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType2: PlaysTypes,
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType2: [...restriccion.playType2, e.target.value],
        });
      } else {
        if (e.target.value === "Todos") {
          setRestriccion({
            ...restriccion,
            playType2: [],
          });
          return;
        }
        setRestriccion({
          ...restriccion,
          playType2: restriccion.playType2.filter(
            (item) => item !== e.target.value
          ),
        });
      }
    }

    else {
      setRestriccion({
        ...restriccion,
        [e.target.name]: e.target.value,
      });
    }
  };
  const addRestriccion = async () => {
    if (props.modalEditar) {
      if (restriccion.type === "PJ") {
        await redApi
          .put(`/restrictions`, {
            ...restriccion,
            id: props.RestriccionSelected._id,
          })
          .then((res) => {
            props.setModalEditar(false);
            props.setRestriccionSelected({});
            Swal.fire({
              icon: "success",
              title: "Restricción editada",
              showConfirmButton: false,
              timer: 1500,
            });
            props.getRestrictions();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      } else if (restriccion.type === "PC" || restriccion.type === "PT") {
        await redApi
          .put(`/restrictions`, {
            id: props.RestriccionSelected._id,
            type: restriccion.type,
            league: restriccion.league,
            playType1: restriccion.playType1,
            playType2: restriccion.playType2,
          })
          .then((res) => {
            props.setModalEditar(false);
            props.setRestriccionSelected({});
            Swal.fire({
              icon: "success",
              title: "Restricción editada",
              showConfirmButton: false,
              timer: 1500,
            });
            props.getRestrictions();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
    } else if (props.modalCrear) {
      if (restriccion.type === "PJ") {
        // console.log({
        //   ...restriccion,
        //   user: props.consorcioSelected
        // })
        await redApi
          .post(`/restrictions`, {
            user: props.consorcioSelected,
            ...restriccion,
          })
          .then((res) => {
            props.setModalCrear(false);
            Swal.fire({
              icon: "success",
              title: "Restricción creada",
              showConfirmButton: false,
              timer: 1500,
            });
            props.getRestrictions();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      } else if (restriccion.type === "PC" || restriccion.type === "PT") {
        // console.log({
        //   type: restriccion.type,
        //   league: restriccion.league,
        //   playType: restriccion.playType,
        //   user: props.consorcioSelected
        // })
        console.log({
          type: restriccion.type,
          league: restriccion.league,
          playType1: restriccion.playType1,
          playType2: restriccion.playType2,
          user: props.consorcioSelected,
        });
        await redApi
          .post(`/restrictions`, {
            type: restriccion.type,
            league: restriccion.league,
            playType1: restriccion.playType1,
            playType2: restriccion.playType2,
            user: props.consorcioSelected,
          })
          .then((res) => {
            props.setModalCrear(false);
            Swal.fire({
              icon: "success",
              title: "Restricción creada",
              showConfirmButton: false,
              timer: 1500,
            });
            props.getRestrictions();
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
    }
  };

  const betType = {
    M: 'EVEN',
    MH: 'EVEN H1',
    MT1: 'EVEN P1',
    MT2: 'EVEN P2',
    MT3: 'EVEN P3',
    R: 'R/L',
    H: 'R/L H1',
    Q1: 'R/L Q1',
    Q2: 'R/L Q2',
    Q3: 'R/L Q3',
    Q4: 'R/L Q4',
    T1: 'R/L P1',
    T2: 'R/L P2',
    T3: 'R/L P3',
    HR2: 'R/L H2',
    '+': 'A Mas',
    'H+': 'A Mas H',
    'Q1+': 'A Mas Q1',
    'Q2+': 'A Mas Q2',
    'Q3+': 'A Mas Q3',
    'Q4+': 'A Mas Q4',
    'T1+': 'A Mas P1',
    'T2+': 'A Mas P2',
    'T3+': 'A Mas P3',
    'H2+': 'A Mas H2',
    '-': 'A Menos',
    'H-': 'A Menos H',
    'Q1-': 'A Menos Q1',
    'Q2-': 'A Menos Q2',
    'Q3-': 'A Menos Q3',
    'Q4-': 'A Menos Q4',
    'T1-': 'A Menos P1',
    'T2-': 'A Menos P2',
    'T3-': 'A Menos P3',
    'H2-': 'A Menos H2',
    'S+': 'Solo +',
    'H1S+': 'Solo +H',
    'Q1S+': 'Solo +Q1',
    'Q2S+': 'Solo +Q2',
    'Q3S+': 'Solo +Q3',
    'Q4S+': 'Solo +Q4',
    'H2S+': 'Solo +H2',
    'S-': 'Solo -',
    'H1S-': 'Solo -H',
    'Q1S-': 'Solo -Q1',
    'Q2S-': 'Solo -Q2',
    'Q3S-': 'Solo -Q3',
    'Q4S-': 'Solo -Q4',
    'H2S-': 'Solo -H2',
    P: 'SR/L',
    Y: 'Si',
    N: 'No',
    'K+': 'K+',
    'K-': 'K-',
    F: '1era',
    C: 'Compra',
    V: 'Venta',
    'C+': 'C A Mas',
    'C-': 'C A Menos',
    J1: 'J1',
    J2: 'J2',
    J3: 'J3',
    J4: 'J4',
    J5: 'J5',
    // G: 'GS ML',
    // 'G+': 'GS A mas',
    // 'G-': 'GSUnder',
    SE: 'Serie',
    AR: 'AR/L',
    T: 'Tercio',
    'T+': 'Tercio Mas',
    'T-': 'Tercio Menos',
    'V+': 'V A Mas',
    'V-': 'V A Menos',
    E: 'Empate',
  }

  const [PlaysTypes, setPlaysTypes] = useState([])

  useEffect(() => {
    console.log(Object.values(betType), "Object.values(betType)")
    setPlaysTypes(['Todos', ...Object.values(betType)])
  }, [])

  // let PlaysTypes = [
  //   "Todos",
  //   "EVEN",
  //   "EVENT H1",
  //   "EVENT P1",
  //   "EVENT P2",
  //   "EVENT P3",
  //   "R/L",
  //   "R/L H1",
  //   "R/L Q1",
  //   "R/L Q2",
  //   "R/L Q3",
  //   "R/L Q4",
  //   "R/L P1",
  //   "R/L P2",
  //   "R/L P3",
  //   "R/L H2",
  //   "A Mas",
  //   "A Mas H",
  //   "A Mas Q1",
  //   "A Mas Q2",
  //   "A Mas Q3",
  //   "A Mas Q4",
  //   "A Mas P1",
  //   "A Mas P2",
  //   "A Mas P3",
  //   "A Mas H2",
  //   "Solo +",
  //   "Solo +H",
  //   "Solo +Q1",
  //   "Solo +Q2",
  //   "Solo +Q3",
  //   "Solo +Q4",
  //   "Solo +H2",
  //   "Solo -",
  //   "Solo -H",
  //   "Solo -Q1",
  //   "Solo -Q2",
  //   "Solo -Q3",
  //   "Solo -Q4",
  //   "Solo -H2",
  //   "SR/L",
  //   "Yes",
  //   "K +",
  //   "K -",
  //   "1 era",
  //   "Compra",
  //   "Venta",
  //   "C a mas",
  //   "C a menos -",
  //   "J1",
  //   "J2",
  //   "J3",
  //   "J4",
  //   "J5",
  //   "GS ML",
  //   "GS a mas",
  //   "GS a menos",
  //   "Serie",
  //   "AR/L",
  //   "Tercio",
  //   "Tercio a mas",
  //   "Tercio a menos",
  //   "V a mas",
  //   "V a menos",
  //   "Empate",
  //   "R/L H1",
  //   "A Mas H",
  //   "A Menos H",
  // ];

  return (
    <Modal
      title={props.modalEditar ? "Editar restricción" : "Crear restricción"}
      open={props.modalEditar || props.modalCrear}
      onOk={handleOk}
      onCancel={handleCancel}
      width="90%"
      style={{ top: 20 }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={addRestriccion}>
          {props.modalEditar ? "Editar restricción" : "Crear restricción"}
        </Button>,
      ]}
    >
      <div className="row d-flex flex-column">
        <div className="row d-flex flex-column my-4">
          <div className="col-12">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-3 text-end">
                <label htmlFor="select-league" className="form-label">
                  Liga
                </label>
                <select
                  name="league"
                  id="select-league"
                  className="form-select"
                  onChange={handlechange}
                >
                  <option disabled selected>
                    {" "}
                    -- Seleccione una liga --{" "}
                  </option>
                  {leagues.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-3 text-end">
                <label htmlFor="select-type" className="form-label">
                  Consorcio
                </label>
                <select
                  name="type"
                  id="select-type"
                  className="form-select"
                  onChange={(e) => props.setConsorcioSelected(e.target.value)}
                >
                  <option disabled selected>
                    {" "}
                    -- Seleccione un consorcio --{" "}
                  </option>
                  {userLevel !== 10 && <option value="admin">admin</option>}
                  {props.consorcios.map((item, index) => {
                    return (
                      <option key={index} value={item._id}>
                        {item.username}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            {restriccion.type === "PJ" ? (
              <>
                <div className="col-4">
                  <h6 className="text-center">Tipo De Ticket</h6>
                </div>
                <div className="col-4">
                  <h6 className="text-center">Tipo De Linea</h6>
                </div>
                <div className="col-4">
                  <h6 className="text-center">Periodo</h6>
                </div>
              </>
            ) : (
              <>
                <div className="col-4">
                  <h6 className="text-center">Tipo De Jugada</h6>
                </div>
                {/* <div className="col-4">
                    <h6 className="text-center">Tipo De Jugada</h6>
                  </div> */}
              </>
            )}
          </div>
        </div>
        <div className="col-12">
          {restriccion.type === "PJ" ? (
            <div className="row d-flex justify-content-center">
              <div className="col-4 d-flex justify-content-start align-items-center flex-column">
                {tipodetikcet.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row d-flex justify-content-center align-items-center w-100"
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="ticketType"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion.ticketType.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-4 d-flex justify-content-startr align-items-center flex-column">
                {tipodelinea.map((item, index) => {
                  return (
                    <div
                      className="row d-flex justify-content-center align-items-center w-100"
                      key={index}
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="lineType"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion.lineType.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-4 d-flex justify-content-start align-items-center flex-column">
                {periodo.map((item, index) => {
                  return (
                    <div
                      className="row d-flex justify-content-center align-items-center w-100"
                      key={index}
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="period"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion.period.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center">
              <div
                className="col-4 d-flex justify-content-start align-items-center flex-column"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                }}
              >
                {PlaysTypes.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row d-flex justify-content-center align-items-center w-100"
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="playType1"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion?.playType1?.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="col-4 d-flex justify-content-start align-items-center flex-column"
                style={{
                  maxHeight: "60vh",
                  overflowY: "auto",
                }}
              >
                {PlaysTypes.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row d-flex justify-content-center align-items-center w-100"
                    >
                      <div className="col-1">
                        <input
                          type="checkbox"
                          name="playType2"
                          value={item}
                          onChange={handlechange}
                          checked={restriccion?.playType2?.includes(item)}
                        />
                      </div>
                      <div className="col-11">
                        <label>{item}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
const Restricciones = ({ toUpdate }) => {
  let buttonsNav = ["Por Jugada", "Por Combinacion", "Por Ticket"];
  const [active, setActive] = useState("Por Jugada");

  const handleActive = (e) => {
    let value = e.target.value;
    setActive(value);
  };

  let buttons = ["Crear", "Editar", "Eliminar", "Refrescar"];
  const [modalCrear, setModalCrear] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [RestriccionSelected, setRestriccionSelected] = useState({});
  const handleClick = (e) => {
    let text = e.target.innerText;
    if (text === "Crear") {
      setModalCrear(true);
    }
    if (text === "Editar") {
      if (RestriccionSelected.consorcio == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes seleccionar un usuario para editar!",
        });
        return;
      }
      setModalEditar(true);
    }
    if (text === "Eliminar") {
      if (!RestriccionSelected?._id) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes seleccionar un usuario para eliminar!",
        });
        return;
      }
      Swal.fire({
        title: "Eliminar",
        text: "¿Estas seguro de eliminar el usuario?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteRestriction(RestriccionSelected._id);
        }
      });
    }
    if (text === "Refrescar") {
      Swal.fire({
        title: "Refrescar",
        text: "¿Estas seguro de refrescar la tabla?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Refrescar",
      }).then((result) => {
        if (result.isConfirmed) {
          getRestrictions();
          Swal.fire("Refrescado!", "La tabla se ha refrescado.", "success");
        }
      });
    }
  };

  const handleSelect = (item) => {
    setRestriccionSelected(item);
  };

  const [restricciones, setRestricciones] = useState([]);
  const [consorcios, setConsorcios] = useState([]);
  const [consorcioSelected, setConsorcioSelected] = useState("");

  const getRestrictions = async () => {
    try {
      await redApi
        .get(`/restrictions?level=2&type=${active === "Por Jugada" ? "PJ" : active === "Por Combinacion" ? "PC" : "PT"}`)
        .then((res) => {
          let NDI = 1
          res.data.map(rest => {
            rest.NDI = NDI
            NDI++
          })
          setRestricciones(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getConsorcios = async () => {
    try {
      await redApi
        .get(`/users?userLevel=2`)
        .then((res) => {
          console.log('RES DATA', res.data);
          setConsorcios(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    // getRestrictions();
    getConsorcios();
  }, []);

  const deleteRestriction = async (id) => {
    try {
      await redApi
        .delete(`/restrictions/${id}`)
        .then((res) => {
          getRestrictions();
          Swal.fire({
            icon: "success",
            title: "Eliminado!",
            text: "La restriccion ha sido eliminada.",
            timer: 2000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const [leagues, setLeagues] = useState([]);
  useEffect(() => {
    redApi
      .get("/leagues")
      .then((res) => {
        setLeagues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [filters, setFilters] = useState({
    pj: {
      user: "",
      league: "",
      lineType: "",
      period: "",
      ticketType: "",
    },
    pc: {
      user: "",
      league: "",
      playType: ""
    },
    pt: {
      user: "",
      league: "",
      playType: "",
    }
  });

  const handleFilter = (e) => {
    let { id, name, value } = e.target;
    setFilters({
      ...filters,
      [id]: {
        ...filters[id],
        [name]: value,
      },
    });
  };

  useEffect(() => {
    switch (active) {
      case "Por Jugada":
        redApi.get(`/restrictions?level=2&type=PJ${filters.pj.user && `&user=${filters.pj.user}`}${filters.pj.league && `&league=${filters.pj.league}`}${filters.pj.lineType && `&lineType=${filters.pj.lineType}`}${filters.pj.period && `&period=${filters.pj.period}`}${filters.pj.ticketType && `&ticketType=${filters.pj.ticketType}`}${filters.pc.playType && `&playType=${filters.pc.playType}`}${filters.pt.playType && `&playType=${filters.pt.playType}`}`).then((res) => {
          let NDI = 1
          res.data.map(rest => {
            rest.NDI = NDI
            NDI++
          })
          setRestricciones(res.data);
        });
        break;
      case "Por Combinacion":
        redApi.get(`/restrictions?level=2&type=PC${filters.pc.user && `&user=${filters.pc.user}`}${filters.pc.league && `&league=${filters.pc.league}`}${filters.pc.playType && `&playType=${filters.pc.playType}`}`).then((res) => {
          let NDI = 1
          res.data.map(rest => {
            rest.NDI = NDI
            NDI++
          })
          setRestricciones(res.data);
        });
        break;
      case "Por Ticket":
        redApi.get(`/restrictions?level=2&type=PT${filters.pt.user && `&user=${filters.pt.user}`}${filters.pt.league && `&league=${filters.pt.league}`}${filters.pt.playType && `&playType=${filters.pt.playType}`}`).then((res) => {
          let NDI = 1
          res.data.map(rest => {
            rest.NDI = NDI
            NDI++
          })
          setRestricciones(res.data);
        });
        break;
      default:
        getRestrictions();
        break;
    }
  }, [filters, active]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="row mb-3">
            <div className="col-6">
              <div className="row px-0">
                {buttonsNav.map((item, index) => {
                  return (
                    <div key={index} className="col-3 px-0">
                      <button
                        style={{ fontSize: "12px" }}
                        value={item}
                        onClick={handleActive}
                        className={`btn w-100 text-white outline-none border-0 rounded-0 text-nowrap ${active === item ? "bg-primary" : "bg-secondary"
                          }`}
                      >
                        {item}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {active === "Por Jugada" ? (
          <>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="row gap-1">
                    {buttons.map((item, index) => {
                      return (
                        <div key={index} className="col-2 p-0 m-0">
                          <button className="btn btn-outline-primary" onClick={handleClick} style={{ fontSize: '12px' }}>{item}</button>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Consorcio
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="user"
                        id="pj"
                        value={filters.pj.user}
                      >
                        <option value="all">Todos</option>
                        {consorcios?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.username}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Liga
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="league"
                        id="pj"
                        value={filters.pj.league}
                      >
                        <option value="">Todos</option>
                        {leagues?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Linea
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="lineType"
                        id="pj"
                        value={filters.pj.lineType}
                      >
                        <option value="">Todos</option>
                        {
                          [
                            "ML",
                            "TL",
                            "RL",
                            "SRL",
                            "Si anotaran",
                            "No anotaran",
                            "Primera carrera",
                            "Ponche",
                            "Solo",
                            "Jugadores",
                            "GS ML",
                            "GS TL",
                            "Tercios",
                            "Serie",
                            "R/L A",
                            "Tercios TL",
                          ].map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        {" "}
                        Periodo
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="period"
                        id="pj"
                        value={filters.pj.period}
                      >
                        <option value="">Todos</option>
                        {
                          [
                            "Juego completo",
                            "Mitad",
                            "Segunda mitad",
                            "Q1",
                            "Q2",
                            "Q3",
                            "Q4",
                            "P1",
                            "P2",
                            "P3",
                          ].map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Ticket
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="ticketType"
                        id="pj"
                        value={filters.pj.ticketType}
                      >
                        <option value="">Todos</option>
                        {
                          [
                            "Directo",
                            "Pale",
                            "Tripleta",
                            "Cuarteta",
                            "Quinteta",
                            "Sexteta",
                            "Septeta",
                            "Octeta",
                            "Noneta",
                            "Decima",
                            "Decima primera",
                            "Decima segunda",
                            "Decima tercera",
                            "Decima cuarta",
                            "Decima quinta",
                            "Decima sexta",
                          ].map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="table-primary w-100">
                    <th scope="col">#</th>
                    <th scope="col">Consorcio</th>
                    <th scope="col">Liga</th>
                    <th scope="col">Linea</th>
                    <th scope="col">Periodo</th>
                    <th scope="col">Ticket</th>
                  </tr>
                </thead>
                <tbody>
                  {restricciones?.map((item, index) => {
                    return (
                      item.type === "PJ" && (
                        <tr
                          key={index}
                          onClick={() => handleSelect(item)}
                          className={
                            RestriccionSelected._id === item._id
                              ? "table-primary"
                              : ""
                          }
                        >
                          <th scope="row">{item?.NDI}</th>
                          <td>{item?.user?.username || "Todos"}</td>
                          <td>{item?.league?.name || "Todas"}</td>
                          <td>
                            {item.lineType?.length > 2
                              ? item.lineType.slice(-2).map((line, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${line}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.lineType.map((line, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${line}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.period?.length > 2
                              ? item.period?.slice(-2).map((period, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${period}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.period?.map((period, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${period}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.ticketType?.length > 2
                              ? item.ticketType
                                ?.slice(-2)
                                ?.map((ticket, index) => {
                                  return (
                                    <div key={index}>
                                      <span>{`${ticket}${index == 1 ? "..." : ""
                                        }`}</span>
                                    </div>
                                  );
                                })
                              : item.ticketType?.map((ticket, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${ticket}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : active === "Por Combinacion" ? (
          <>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="row gap-1">
                    {buttons.map((item, index) => {
                      return (
                        <div key={index} className="col-2 p-0 m-0">
                          <button className="btn btn-outline-primary" onClick={handleClick} style={{ fontSize: '12px' }}>{item}</button>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Consorcio
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="user"
                        id="pc"
                        value={filters.pc.user}
                      >
                        <option value="all">Todos</option>
                        {consorcios?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.username}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Liga
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="league"
                        id="pc"
                        value={filters.pc.league}
                      >
                        <option value="todos">Todos</option>
                        {leagues?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Tipo de jugada
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="playType"
                        id="pc"
                        value={filters.pc.playType}
                      >
                        <option value="">Todos</option>
                        {
                          [
                            "EVEN",
                            "EVENT H1",
                            "EVENT P1",
                            "EVENT P2",
                            "EVENT P3",
                            "R/L",
                            "R/L H1",
                            "R/L Q1",
                            "R/L Q2",
                            "R/L Q3",
                            "R/L Q4",
                            "R/L P1",
                            "R/L P2",
                            "R/L P3",
                            "R/L H2",
                            "A Mas",
                            "A Mas H",
                            "A Mas Q1",
                            "A Mas Q2",
                            "A Mas Q3",
                            "A Mas Q4",
                            "A Mas P1",
                            "A Mas P2",
                            "A Mas P3",
                            "A Mas H2",
                            "Solo +",
                            "Solo +H",
                            "Solo +Q1",
                            "Solo +Q2",
                            "Solo +Q3",
                            "Solo +Q4",
                            "Solo +H2",
                            "Solo -",
                            "Solo -H",
                            "Solo -Q1",
                            "Solo -Q2",
                            "Solo -Q3",
                            "Solo -Q4",
                            "Solo -H2",
                            "SR/L",
                            "Yes",
                            "K +",
                            "K -",
                            "1 era",
                            "Compra",
                            "Venta",
                            "C a mas",
                            "C a menos -",
                            "J1",
                            "J2",
                            "J3",
                            "J4",
                            "J5",
                            "GS ML",
                            "GS a mas",
                            "GS a menos",
                            "Serie",
                            "AR/L",
                            "Tercio",
                            "Tercio a mas",
                            "Tercio a menos",
                            "V a mas",
                            "V a menos",
                            "Empate",
                            "R/L H1",
                            "A Mas H",
                            "A Menos H",
                          ].map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="table-primary w-100">
                    <th scope="col">#</th>
                    <th scope="col">Consorcio</th>
                    <th scope="col">Liga</th>
                    <th scope="col">Tipo de Jugada</th>
                    <th scope="col">Tipo de Jugada</th>
                  </tr>
                </thead>
                <tbody>
                  {restricciones?.map((item, index) => {
                    return (
                      item.type === "PC" && (
                        <tr
                          key={index}
                          onClick={() => handleSelect(item)}
                          className={
                            RestriccionSelected._id === item._id
                              ? "table-primary"
                              : ""
                          }
                        >
                          <th scope="row">{item?.NDI}</th>
                          <td>{item?.user?.username || "Todos"}</td>
                          <td>{item?.league?.name || "Todas"}</td>
                          <td>
                            {item.playType1?.length > 2
                              ? item.playType1.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType1.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.playType2?.length > 2
                              ? item.playType2.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType2.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <div className="col-12">
              <div className="row mb-3">
                <div className="col-6">
                  <div className="row gap-1">
                    {buttons.map((item, index) => {
                      return (
                        <div key={index} className="col-2 p-0 m-0">
                          <button className="btn btn-outline-primary" onClick={handleClick} style={{ fontSize: '12px' }}>{item}</button>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 ">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Consorcio
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="user"
                        id="pt"
                        value={filters.pt.user}
                      >
                        <option value="all">Todos</option>
                        {consorcios?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.username}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Liga
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="league"
                        id="pt"
                        value={filters.pt.league}
                      >
                        <option value="todos">Todos</option>
                        {leagues?.map((item, index) => {
                          return (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ width: "20%" }}
                    >
                      <label
                        className="col-form-label col-form-label-sm w-25"
                        style={{ fontSize: "12px" }}
                      >
                        Tipo de jugada
                      </label>
                      <select
                        className="w-50 form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => handleFilter(e)}
                        name="playType"
                        id="pt"
                        value={filters.pt.playType}
                      >
                        <option value="">Todos</option>
                        {
                          [
                            "EVEN",
                            "EVENT H1",
                            "EVENT P1",
                            "EVENT P2",
                            "EVENT P3",
                            "R/L",
                            "R/L H1",
                            "R/L Q1",
                            "R/L Q2",
                            "R/L Q3",
                            "R/L Q4",
                            "R/L P1",
                            "R/L P2",
                            "R/L P3",
                            "R/L H2",
                            "A Mas",
                            "A Mas H",
                            "A Mas Q1",
                            "A Mas Q2",
                            "A Mas Q3",
                            "A Mas Q4",
                            "A Mas P1",
                            "A Mas P2",
                            "A Mas P3",
                            "A Mas H2",
                            "Solo +",
                            "Solo +H",
                            "Solo +Q1",
                            "Solo +Q2",
                            "Solo +Q3",
                            "Solo +Q4",
                            "Solo +H2",
                            "Solo -",
                            "Solo -H",
                            "Solo -Q1",
                            "Solo -Q2",
                            "Solo -Q3",
                            "Solo -Q4",
                            "Solo -H2",
                            "SR/L",
                            "Yes",
                            "K +",
                            "K -",
                            "1 era",
                            "Compra",
                            "Venta",
                            "C a mas",
                            "C a menos -",
                            "J1",
                            "J2",
                            "J3",
                            "J4",
                            "J5",
                            "GS ML",
                            "GS a mas",
                            "GS a menos",
                            "Serie",
                            "AR/L",
                            "Tercio",
                            "Tercio a mas",
                            "Tercio a menos",
                            "V a mas",
                            "V a menos",
                            "Empate",
                            "R/L H1",
                            "A Mas H",
                            "A Menos H",
                          ].map((item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="table-primary w-100">
                    <th scope="col">#</th>
                    <th scope="col">Consorcio</th>
                    <th scope="col">Liga</th>
                    <th scope="col">Tipo de Jugada</th>
                    <th scope="col">Tipo de Jugada</th>
                  </tr>
                </thead>
                <tbody>
                  {restricciones?.map((item, index) => {
                    return (
                      item.type === "PT" && (
                        <tr
                          key={index}
                          onClick={() => handleSelect(item)}
                          className={
                            RestriccionSelected._id === item._id
                              ? "table-primary"
                              : ""
                          }
                        >
                          <th scope="row">{item?.NDI}</th>
                          <td>{item?.user?.username || "Todos"}</td>
                          <td>{item?.league?.name || "Todas"}</td>
                          <td>
                            {item.playType1?.length > 2
                              ? item.playType1.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType1.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                          <td>
                            {item.playType2?.length > 2
                              ? item.playType2.slice(-2).map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })
                              : item.playType2.map((play, index) => {
                                return (
                                  <div key={index}>
                                    <span>{`${play}${index == 1 ? "..." : ""
                                      }`}</span>
                                  </div>
                                );
                              })}
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
        {modalCrear ? (
          <ModalRestricciones
            setConsorcioSelected={setConsorcioSelected}
            consorcioSelected={consorcioSelected}
            consorcios={consorcios}
            setRestricciones={setRestricciones}
            restricciones={restricciones}
            setModalCrear={setModalCrear}
            modalCrear={modalCrear}
            toUpdate={toUpdate}
            resType={active}
            getRestrictions={getRestrictions}
          />
        ) : null}
        {modalEditar ? (
          <ModalRestricciones
            setConsorcioSelected={setConsorcioSelected}
            consorcioSelected={consorcioSelected}
            consorcios={consorcios}
            setRestricciones={setRestricciones}
            restricciones={restricciones}
            setModalEditar={setModalEditar}
            modalEditar={modalEditar}
            RestriccionSelected={RestriccionSelected}
            setRestriccionSelected={setRestriccionSelected}
            toUpdate={toUpdate}
            resType={active}
            getRestrictions={getRestrictions}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Restricciones;
